import { Search } from "@ignite-analytics/icons";
import { InputAdornment, TextField } from "@mui/material";
import debounce from "lodash/debounce";
import React from "react";
import { useIntl } from "react-intl";
import messages from "./messages";

interface Props {
    onSearch: (term: string) => void;
    debounceMs?: number;
    initialValue?: string;
}

const useDebounce = (fn: (term: string) => void, debounceMs: number) => {
    // Always use latest fn without needing to re-create the debounced function
    const fnRef = React.useRef(fn);
    React.useEffect(() => {
        fnRef.current = fn;
    }, [fn]);

    return React.useMemo(
        () =>
            debounce(
                (term: string) => {
                    fnRef.current(term);
                },
                debounceMs,
                { leading: false, trailing: true }
            ),
        [debounceMs]
    );
};

const DEFAULT_DEBOUNCE_MS = 300;

export const SearchField = ({ onSearch, debounceMs = DEFAULT_DEBOUNCE_MS, initialValue }: Props) => {
    const [searchTerm, setSearchTerm] = React.useState(initialValue ?? "");

    const { formatMessage } = useIntl();
    const debouncedSearch = useDebounce(onSearch, debounceMs);

    const forceSearch = () => {
        debouncedSearch(searchTerm);
        debouncedSearch.flush();
    };

    return (
        <TextField
            defaultValue={searchTerm}
            component="form"
            onSubmit={(e) => {
                e.preventDefault();
                forceSearch();
            }}
            data-testid="search-textfield"
            onChange={(e) => {
                const newSearchTerm = e.target.value;

                setSearchTerm(newSearchTerm);
                debouncedSearch(newSearchTerm);
                // Flush on empty search to get a snappier "back to start"
                if (e.target.value === "") debouncedSearch.flush();
            }}
            size="medium"
            sx={{
                width: "256px",
                // no margin on input
                "& .MuiInputBase-root": { margin: 0 },
            }}
            placeholder={formatMessage(messages.searcField)}
            InputProps={{
                startAdornment: (
                    <InputAdornment position="start">
                        <Search cursor="Pointer" fontSize="small" />
                    </InputAdornment>
                ),
            }}
            role="search"
        />
    );
};

import { ShapeIcon } from "@ignite-analytics/components";
import { InformationCircleSolid, X } from "@ignite-analytics/icons";
import { Button, IconButton, Snackbar as BaseSnackbar, Stack, Typography } from "@mui/material";
import React from "react";
import { FormattedMessage } from "react-intl";

export interface SnackbarProps {
    open: boolean;
    message: React.ReactNode;
    href?: string;
    onClose: () => void;
}

export const Snackbar: React.FC<SnackbarProps> = ({ open, message, href, onClose }) => {
    return (
        <BaseSnackbar
            open={open}
            autoHideDuration={6000}
            onClose={onClose}
            sx={{ backgroundColor: "#EFF8FE", borderColor: "#7DD2FB" }}
            message={
                <Stack direction="row" spacing={1} alignItems="center">
                    <Stack paddingRight={1}>
                        <ShapeIcon variant="outline" color="accent" size="xsmall">
                            <InformationCircleSolid color="quarternary" fontSize="large" />
                        </ShapeIcon>
                    </Stack>
                    <Stack direction="row">{message}</Stack>
                </Stack>
            }
            action={
                <Stack direction="row" alignItems="center" spacing={1} px={2}>
                    {href && (
                        <Button href={href} color="secondary" size="xsmall" sx={{ borderColor: "#7DD2FB" }}>
                            <Typography variant="textSm" color="#075884">
                                <FormattedMessage defaultMessage="Go to initiative" />
                            </Typography>
                        </Button>
                    )}
                    <IconButton onClick={onClose}>
                        <X />
                    </IconButton>
                </Stack>
            }
            anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        />
    );
};

export const overrideColumnWidth = (
    columnWidths: { id: string; width: number }[],
    columnWidth: { id: string; width: number }
): { id: string; width: number }[] => {
    const widthToReplace = columnWidths.find((col) => col.id === columnWidth.id);
    if (widthToReplace) {
        widthToReplace.width = columnWidth.width;
        return columnWidths;
    }
    return [...columnWidths, columnWidth];
};

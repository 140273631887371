import { FormattedMessage } from "react-intl";
import { ActiveChip, ExpiredChip, ExpiringInDaysChip, ExpiringInMonthsChip } from "./chips";

const MILLISECONDS_PER_DAY = 24 * 60 * 60 * 1000;
const DAYS_PER_MONTH = 30;
const MILLISECONDS_PER_MONTH = DAYS_PER_MONTH * MILLISECONDS_PER_DAY;

export const getStatusChipContent = (endDate: Date) => {
    const timeNow = new Date();

    const getExpiringSoonChip = (endDate: Date) => {
        const timeNow = new Date();
        const timeDifference = endDate.getTime() - timeNow.getTime();
        const months = Math.floor(timeDifference / MILLISECONDS_PER_MONTH);
        const days = Math.floor(timeDifference / MILLISECONDS_PER_DAY);
        if (months > 0) {
            return <ExpiringInMonthsChip months={months} />;
        }
        return <ExpiringInDaysChip days={days} />;
    };

    // If end date is before now, return "expired"
    if (new Date(endDate) < timeNow) {
        return { chip: <ExpiredChip />, tooltipTitle: <FormattedMessage defaultMessage="Expired" /> };
    }

    // If end date is before 6 months from now, return "expiring soon"
    const sixMonthsFromNow = new Date(timeNow);
    sixMonthsFromNow.setMonth(timeNow.getMonth() + 6);
    if (new Date(endDate) < sixMonthsFromNow) {
        // Calculate months and days until expiry
        return {
            chip: getExpiringSoonChip(endDate),
            tooltipTitle: <FormattedMessage defaultMessage="Expiring in less than six months" />,
        };
    }
    // If end date is after 6 months from now, return "active"
    return {
        chip: <ActiveChip />,
        tooltipTitle: <FormattedMessage defaultMessage="Expiring in more than six months" />,
    };
};

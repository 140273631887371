import { DotsVertical, InformationCircle, Trash } from "@ignite-analytics/icons";
import { track } from "@ignite-analytics/track";
import { Box, Checkbox, Grid, IconButton, Link as MuiLink, Stack, Tooltip, Typography } from "@mui/material";
import React, { useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { EmtpyCardContent } from "@/components/EmptyCardContent";
import { RiddleMenu } from "../ui/Menu";
import { DocumentDrop } from "./DocumentDrop";
import { Attachment } from "./hooks";

interface Props {
    edit: boolean;
    canEdit: boolean;
    attachments: Attachment[];
    onToggleVisibility: (id: string, checked: boolean) => Promise<void>;
    onDelete: (id: string) => Promise<void>;
    upload: (file: File) => Promise<void>;
}

export const FileAttachments = ({ upload, attachments, onToggleVisibility, onDelete, edit, canEdit }: Props) => {
    const [focusedRow, setFocusedRow] = useState<Attachment | null>(null);
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const { formatMessage } = useIntl();

    if (attachments.length === 0) {
        return (
            <Stack spacing={2}>
                <EmtpyCardContent
                    title={formatMessage({ defaultMessage: "No documents" })}
                    subtitle={formatMessage({
                        defaultMessage: "You can upload files by using the Edit contract option",
                    })}
                />
                <DocumentDrop
                    disabled={!canEdit}
                    onChange={async (files) => {
                        const promises = [];
                        for (let i = 0; i < files.length; i++) {
                            promises.push(upload(files[i]));
                        }
                        await Promise.all(promises); // what do we do with this...
                    }}
                />
            </Stack>
        );
    }

    // The dimensions can differ.
    // 1) If we're not editing, then the title "takes" the actions column's space.
    // 2) If we're on a large screen, we allocate more space for the title. On small screens,
    // the title and access + action columns have equal space.
    const dims = {
        xs: { title: edit ? 6 : 10, access: edit ? 3 : 2, actions: edit ? 3 : 0 },
        lg: { title: edit ? 8 : 10, access: edit ? 2 : 2, actions: edit ? 2 : 0 },
    };

    return (
        <>
            <Grid container alignItems="center" py={1.25} rowGap={2}>
                <Grid item xs={dims.xs.title} lg={dims.lg.title}>
                    <Typography fontWeight="500" variant="textSm">
                        <FormattedMessage defaultMessage="File name" />
                    </Typography>
                </Grid>
                <Grid item xs={dims.xs.access} lg={dims.lg.access}>
                    <Stack gap={0.75} direction="row" alignItems="center">
                        <Typography fontWeight="500" variant="textSm">
                            <FormattedMessage defaultMessage="Public" />
                        </Typography>
                        <Tooltip
                            title={
                                <FormattedMessage defaultMessage="Here you can view the document's access level: public or private" />
                            }
                        >
                            <InformationCircle fontSize="small" />
                        </Tooltip>
                    </Stack>
                </Grid>
                {edit && (
                    <Grid item xs={dims.xs.actions} lg={dims.lg.actions}>
                        <Box display="flex" alignItems="center">
                            <Typography fontWeight="500" variant="textSm">
                                <FormattedMessage defaultMessage="Actions" />
                            </Typography>
                        </Box>
                    </Grid>
                )}
                {attachments.map((att) => (
                    <React.Fragment key={att.id}>
                        <Grid
                            item
                            xs={dims.xs.title}
                            lg={dims.lg.title}
                            pr={4}
                            gap={0.5}
                            sx={{
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                                whiteSpace: "nowrap",
                            }}
                        >
                            <MuiLink
                                onClick={() => track("Contract Details: File downloaded")}
                                href={att.url!}
                                target="_blank"
                                rel="noreferrer noopener"
                            >
                                {att.name}
                            </MuiLink>
                        </Grid>
                        <Grid item xs={dims.xs.access} lg={dims.lg.access}>
                            <Box display="flex" alignItems="center">
                                <Checkbox
                                    disabled={!edit || !canEdit}
                                    defaultChecked={att.isPublic}
                                    onChange={(_, checked) => onToggleVisibility(att.id, checked)}
                                />
                            </Box>
                        </Grid>
                        {edit && (
                            <Grid item xs={dims.xs.actions} lg={dims.lg.actions}>
                                <Box display="flex" alignItems="center">
                                    <IconButton
                                        onClick={(e) => {
                                            setAnchorEl(e.currentTarget);
                                            setFocusedRow(att);
                                        }}
                                        size="2xsmall"
                                    >
                                        <DotsVertical />
                                    </IconButton>
                                </Box>
                            </Grid>
                        )}
                    </React.Fragment>
                ))}
                <Grid item xs={12}>
                    <DocumentDrop
                        disabled={!canEdit}
                        onChange={async (files) => {
                            const promises = [];
                            for (let i = 0; i < files.length; i++) {
                                promises.push(upload(files[i]));
                            }
                            await Promise.all(promises); // what do we do with this...
                        }}
                    />
                </Grid>
            </Grid>

            <RiddleMenu
                anchorEl={anchorEl}
                sx={{ width: 240 }}
                entries={[
                    {
                        label: formatMessage({ defaultMessage: "Delete" }),
                        danger: true,
                        onClick: async () => onDelete(focusedRow!.id),
                        icon: { pos: "start", component: <Trash /> },
                    },
                ]}
                onClose={() => setAnchorEl(null)}
            />
        </>
    );
};

import { ColumnHeader } from "@/components/ContractsTablePro/column";
import { ContractFields } from "@/types";
import { CONTRACT_STATUS_COLUMN_ID } from "./generatedColumnIds";

export const mapToHeaders = (
    metadata: ContractFields,
    columnWidths: { id: string; width: number }[],
    currency: string | undefined,
    formatMessage: (message: { defaultMessage: string }) => string,
    contractStatusToggle: boolean
): ColumnHeader[] => {
    let headers = [
        {
            id: metadata.specificFields.title.id,
            fieldType: "title",
            label: formatMessage({ defaultMessage: "Contract title" }).toString(),
            width: columnWidths.find((c) => c.id === metadata.specificFields.title.id)?.width ?? 200,
        },
        {
            id: metadata.specificFields.supplier.id,
            fieldType: "supplier",
            label: metadata.specificFields.supplier.name,
            width: columnWidths.find((c) => c.id === metadata.specificFields.supplier.id)?.width ?? 200,
        },
        ...(metadata.specificFields.totalSpend !== undefined
            ? [
                  {
                      id: metadata.specificFields.totalSpend.id,
                      fieldType: "totalSpend",
                      label: (currency ? ` (${currency})` : "") + metadata.specificFields.totalSpend.name,
                      align: "right",
                      width: columnWidths.find((c) => c.id === metadata.specificFields.totalSpend?.id)?.width ?? 165,
                  } as const,
              ]
            : []),
        {
            id: metadata.specificFields.description.id,
            fieldType: "description",
            label: metadata.specificFields.description.name,
            width: columnWidths.find((c) => c.id === metadata.specificFields.description.id)?.width ?? 200,
        },
        {
            id: metadata.specificFields.startDate.id,
            fieldType: "startDate",
            label: metadata.specificFields.startDate.name,
            width: columnWidths.find((c) => c.id === metadata.specificFields.startDate.id)?.width ?? 120,
        },
        {
            id: metadata.specificFields.endDate.id,
            fieldType: "endDate",
            label: metadata.specificFields.endDate.name,
            width: columnWidths.find((c) => c.id === metadata.specificFields.endDate.id)?.width ?? 120,
        },
        ...(metadata.specificFields.renewalDate !== undefined
            ? [
                  {
                      id: metadata.specificFields.renewalDate.id,
                      fieldType: "renewalDate",
                      label: metadata.specificFields.renewalDate.name,
                      align: "right",
                      width: columnWidths.find((c) => c.id === metadata.specificFields.renewalDate?.id)?.width ?? 120,
                  } as const,
              ]
            : []),
        {
            id: metadata.specificFields.contractResponsible.id,
            fieldType: "contractResponsible",
            label: metadata.specificFields.contractResponsible.name,
            width: columnWidths.find((c) => c.id === metadata.specificFields.contractResponsible.id)?.width ?? 200,
        },
    ].concat(
        metadata.customFields.map((cf) => ({
            id: cf.id,
            fieldType: "custom",
            label: cf.name,
            width: columnWidths.find((c) => c.id === cf.id)?.width ?? 200,
        }))
    );

    headers = headers.concat({
        id: "isprivate",
        fieldType: "isprivate",
        label: formatMessage({ defaultMessage: "Access" }),
        width: columnWidths.find((c) => c.id === "isprivate")?.width ?? 120,
    });

    // generated columns
    const generatedColumns = [];

    if (contractStatusToggle) {
        generatedColumns.push({
            id: CONTRACT_STATUS_COLUMN_ID,
            fieldType: CONTRACT_STATUS_COLUMN_ID,
            label: formatMessage({ defaultMessage: "Contract status" }),
            width: columnWidths.find((c) => c.id === CONTRACT_STATUS_COLUMN_ID)?.width ?? 200,
        });
    }

    return [...headers, ...generatedColumns];
};

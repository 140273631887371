import { Filter } from "@ignite-analytics/filters";
import {
    Navigate,
    SearchSchemaInput,
    createRootRouteWithContext,
    createRoute,
    createRouter,
    defer,
    stringifySearchWith,
} from "@tanstack/react-router";
import dayjs from "dayjs";
import { z } from "zod";
import { Contract, Session } from "@/types";
import { GetContractsInput } from "@/generated/graphql";
import { ContractsNotConfiguredErrorComponent } from "./components/ContractsNotConfiguredErrorComponent";
import { getContractStatistics } from "./components/StatisticsCards/loaders";
import { FullWidthSpinner } from "./components/ui/FullWidthSpinner";
import { queryClient } from "./contexts";
import { currentIntl } from "./contexts/IntlContext";
import { getSupplierNameById } from "./hooks/useFetchSupplier";
import { contractDetailQueryKey, defaultLayoutQueryKey, supplierNameQueryKey } from "./querykeys";
import { Root } from "./routes/__root";
import { AskDocumentExperimentPage } from "./routes/askDocumentExperiment";
import { DetailPage } from "./routes/detail/$id";
import { getContractById } from "./routes/detail/loaders";
import { LayoutsPage } from "./routes/layouts/$id";
import { CONTRACT_STATUS_COLUMN_ID } from "./routes/list/columns/generatedColumnIds";
import FilterWrappedContractList from "./routes/list/FilterWrappedContractList";
import {
    contractsFromGraphql,
    getContractMetadata,
    getLayoutByIdOrDefault,
    loadContracts,
} from "./routes/list/loaders";
import { OverviewComponent } from "./routes/overview";

// Create a root route
const rootRoute = createRootRouteWithContext<{ session: Session }>()({
    component: Root,
});

const indexRoute = createRoute({
    getParentRoute: () => rootRoute,
    path: "/",
    component: () => <Navigate to="/list" search={{ status: "active" }} />,
});

const contractLibraryRoute = createRoute({
    getParentRoute: () => rootRoute,
    path: "library",
    component: () => <Navigate to="/list" search={{ status: "active" }} />,
});

const getSortByKey = (sortBy: string) => {
    if (sortBy === CONTRACT_STATUS_COLUMN_ID) {
        return "endDate";
    }
    return sortBy;
};

const contractOverviewValidateSearchSchema = z.object({
    group: z.union([z.literal("currentUser"), z.literal("all")]).catch("all"),
    show: z
        .union([z.literal("demo"), z.literal("learn")])
        .optional()
        .catch(undefined),
});
type ContractOverviewSearchInput = Partial<z.infer<typeof contractOverviewValidateSearchSchema>>;

export const contractOverviewRoute = createRoute({
    getParentRoute: () => rootRoute,
    path: "overview",
    component: OverviewComponent,
    errorComponent: ContractsNotConfiguredErrorComponent,
    staleTime: 1000 * 60 * 15,
    validateSearch: (input: ContractOverviewSearchInput & SearchSchemaInput) =>
        contractOverviewValidateSearchSchema.parse(input),
    loaderDeps: ({ search }) => search,
    loader: async ({ context, deps: { group } }) => {
        const columns = await getContractMetadata();
        const stats = defer(getContractStatistics(group === "currentUser" ? context.session.id : null));
        return {
            missingRenewalDate: columns.specificFields.renewalDate === undefined,
            deferredStats: stats,
        };
    },
});

const literalSchema = z.union([z.string(), z.number(), z.boolean(), z.null()]);
type Literal = z.infer<typeof literalSchema>;
type Json = Literal | { [key: string]: Json } | Json[];
const jsonSchema: z.ZodType<Json> = z.lazy(() => z.union([literalSchema, z.array(jsonSchema), z.record(jsonSchema)]));

const contractListValidateSearchSchema = z.object({
    status: z
        .union([
            z.literal("active"),
            z.literal("renewing-soon"),
            z.literal("expiring-soon"),
            z.literal("all"),
            z.literal("upcoming"),
            z.literal("expired"),
        ])
        .catch("all"),
    newContract: z
        .object({
            open: z.boolean(),
            supplier: z
                .object({
                    id: z.string(),
                    label: z.string(),
                })
                .optional(),
        })
        .optional(),
    dmsFilter: jsonSchema.optional().transform((e) => {
        if (!e) return undefined;
        return e as any as Filter[];
    }),
    page: z.number().int().positive().catch(1),
    perPage: z.number().int().positive().catch(25),
    searchTerm: z
        .preprocess((val) => String(val), z.string())
        .optional()
        .transform((val) => (val === "" ? undefined : val))
        .catch(""),
    sortBy: z.preprocess((val) => String(val), z.string()).optional(),
    sortOrder: z
        .union([z.literal("asc"), z.literal("desc"), z.literal(null)])
        .optional()
        .catch(undefined),
    group: z.union([z.literal("currentUser"), z.literal("all")]).catch("all"),
});

// we'll create a separate type for the input to validateSearch. Everything is optional, and we'll
// fall back to a default value if not provided.
export type ContractListSearchInput = Partial<z.infer<typeof contractListValidateSearchSchema>>;
export const contractListRoute = createRoute({
    getParentRoute: () => rootRoute,
    path: "list",
    component: FilterWrappedContractList,
    validateSearch: (input: ContractListSearchInput & SearchSchemaInput) =>
        contractListValidateSearchSchema.parse(input),
    loaderDeps: ({ search }) => search,
    loader: async ({ context, deps: { dmsFilter, status, perPage, page, sortBy, sortOrder, searchTerm, group } }) => {
        const { id: userId } = context.session;
        const filters = dmsFilter ?? [];
        if (page > 0) {
            // eslint-disable-next-line no-param-reassign
            page -= 1;
        }

        // Fetch from graphql. This should not be used in production, but can be toggled on during development
        // by setting the value to true. The table itself is also rendered differently because the data shape is different.
        // If we don't fetch from graphql, the "v2" field is null.
        const experimentalFetchFromGraphql = Boolean(0 && import.meta.env.MODE !== "production");
        if (experimentalFetchFromGraphql) {
            const columns = await getContractMetadata();
            const filter: GetContractsInput = {};
            if (searchTerm !== undefined && searchTerm.length > 0) {
                filter.search = searchTerm;
            }
            if (group === "currentUser") {
                filter.responsibles = [userId];
            }
            if (status === "active") {
                filter.endDateAfter = dayjs().format("YYYY-MM-DD");
                filter.startDateBefore = dayjs().format("YYYY-MM-DD");
            } else if (status === "expiring-soon") {
                filter.endDateBefore = dayjs().add(6, "months").format("YYYY-MM-DD");
                filter.endDateAfter = dayjs().format("YYYY-MM-DD");
            } else if (status === "renewing-soon") {
                filter.renewalDateBefore = dayjs().add(6, "months").format("YYYY-MM-DD");
                filter.renewalDateAfter = dayjs().format("YYYY-MM-DD");
            }
            const data = await contractsFromGraphql(filter);
            return {
                newTable: experimentalFetchFromGraphql,
                contracts: null,
                columns,
                v2: { total: data.length, data },
            };
        }

        const { contracts, unmapped, columns } = await queryClient.ensureQueryData({
            queryKey: [
                context.session.tenant,
                "contracts",
                { filters, status, perPage, page, sortBy, sortOrder, searchTerm, group },
            ],
            queryFn: () =>
                loadContracts({
                    status,
                    pagination: { page, perPage },
                    sort: sortBy && sortOrder ? { sortBy: getSortByKey(sortBy), sortOrder } : undefined,
                    filters,
                    searchTerm,
                }),
        });
        unmapped.forEach((row) => {
            if (row.supplierId) {
                const { value, label } = row.supplierId;
                if (value) {
                    queryClient.setQueryData(supplierNameQueryKey(context.session.tenant, value), label);
                }
            }
            const contractResponsibleIds: string[] = [];
            row.contractResponsibleIds.forEach((labelValue) => {
                const value = labelValue?.value;
                if (value) contractResponsibleIds.push(value);
            });
            const contactPersonIds: string[] = [];
            row.contactPersonIds.forEach((labelValue) => {
                const value = labelValue?.value;
                if (value) contactPersonIds.push(value);
            });

            const contract: Contract = {
                id: row.id,
                title: row.title,
                description: row.description,
                startDate: row.startDate,
                endDate: row.endDate,
                renewalDate: row.renewalDate,
                contractResponsibleIds,
                contactPersonIds,
                supplierId: row.supplierId?.value ?? null,
                sourcingLink: null,
                totalSpend: row.totalSpend,
                customFields: row.customFields as any as Contract["customFields"],
                isPrivate: row.isPrivate,
            };
            const cacheContract = false;
            if (cacheContract) {
                queryClient.setQueryData(contractDetailQueryKey(context.session.tenant, contract.id), contract);
            }
        });

        return { contracts, columns, v2: null };
    },
});

export const layoutDetailsRoute = createRoute({
    getParentRoute: () => rootRoute,
    path: "layouts/$id",
    component: LayoutsPage,
});

export const contractDetailRoute = createRoute({
    getParentRoute: () => rootRoute,
    pendingComponent: FullWidthSpinner,
    pendingMs: 0,
    path: "detail/$id",
    component: DetailPage,
    loader: async ({ context, params }) => {
        const contract = await queryClient.ensureQueryData({
            queryKey: contractDetailQueryKey(context.session.tenant, params.id),
            queryFn: () => getContractById(params.id),
        });
        let layout = await queryClient.ensureQueryData({
            queryKey: defaultLayoutQueryKey(context.session.tenant),
            queryFn: () => getLayoutByIdOrDefault("default"),
        });

        if (layout === undefined || layout === null) {
            // fallback to a default layout, which contain all custom fields
            layout = {
                id: "tmp",
                groups: [
                    {
                        name: currentIntl().formatMessage({ defaultMessage: "Other details" }),
                        items: contract.customFields.map((field, index) => ({
                            refId: field.id,
                            name: field.name,
                            visible: true,
                            order: index,
                        })),
                        visible: true,
                        order: 1,
                    },
                ],
            };
        }
        let supplierName: string | null = null;
        if (contract.supplierId !== null && contract.supplierId !== undefined && contract.supplierId !== "") {
            supplierName = await queryClient.ensureQueryData({
                queryKey: supplierNameQueryKey(context.session.tenant, contract.supplierId),
                queryFn: () => getSupplierNameById(contract.supplierId!),
            });
        }
        return { contract, supplierName, layout };
    },
});

export const askDocumentExperimentRoute = createRoute({
    getParentRoute: () => rootRoute,
    path: "ask-document-experiment",
    component: AskDocumentExperimentPage,
});

const routeTree = rootRoute.addChildren([
    indexRoute,
    contractListRoute,
    contractOverviewRoute,
    contractLibraryRoute,
    layoutDetailsRoute,
    contractDetailRoute,
    askDocumentExperimentRoute,
]);

export const router = createRouter({
    context: { session: { id: "", email: "", tenant: "" } }, // placeholder - will be populated by RouterProvider
    defaultPreload: "intent",
    routeTree,
    defaultNotFoundComponent: () => "404 Not Found",
    stringifySearch: (search) => {
        // Shallow copy that we can mutate
        const stringifiedSearch = { ...search };

        if (stringifiedSearch.searchTerm === "") {
            // special-case: to avoid ?searchTerm= in the URL, we can just omit it in case it's empty
            // eslint-disable-next-line no-param-reassign
            delete stringifiedSearch.searchTerm;
        }
        if (stringifiedSearch.page === 1) {
            // special-case: to avoid ?page=1 in the URL, we can just omit it in case it's 0
            // eslint-disable-next-line no-param-reassign
            delete stringifiedSearch.page;
        }
        if (stringifiedSearch.perPage === 25) {
            // omit ?perPage=25 in the URL
            // eslint-disable-next-line no-param-reassign
            delete stringifiedSearch.perPage;
        }
        if (stringifiedSearch.sortOrder === false) {
            // omit ?sortOrder=false in the URL
            // eslint-disable-next-line no-param-reassign
            delete stringifiedSearch.sortOrder;
        }
        if (stringifiedSearch.group === "all") {
            delete stringifiedSearch.group;
        }
        return stringifySearchWith(JSON.stringify)(stringifiedSearch);
    },
});

// https://tanstack.com/router/latest/docs/framework/react/guide/creating-a-router#router-type-safety
declare module "@tanstack/react-router" {
    interface Register {
        // This infers the type of our router and registers it across your entire project
        router: typeof router;
    }
}

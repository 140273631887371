import { DotsVertical, Pen, Trash } from "@ignite-analytics/icons";
import { LoadingButton } from "@mui/lab";
import { Button, IconButton, Stack, TextField, Typography } from "@mui/material";
import dayjs from "dayjs";
import React, { useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useSessionContext } from "@/contexts/SessionContext";
import { UserRow } from "@/components/UserDisplay/Row";
import { RiddleMenu } from "@/components/ui/Menu";
import { Note } from "../Notes/models";

type Props = {
    notes: Note[];
    onCreate: (text: string) => Promise<void>;
    onDelete: (id: string) => Promise<void>;
    onUpdate: (id: string, text: string) => Promise<void>;
};

type AddNoteSectionProps = {
    initial?: string;
    onSubmit: (text: string) => Promise<void>;
    saveLabel?: string;
    inputProps?: React.ComponentProps<typeof TextField>;
    onCancel?: () => void;
    hidden?: boolean;
};

const AddNoteSection = ({
    initial,
    onSubmit,
    inputProps,
    onCancel,
    hidden,
    saveLabel = "Save",
}: AddNoteSectionProps) => {
    const [text, setText] = React.useState(initial ?? "");
    const [loading, setLoading] = React.useState(false);
    const { formatMessage } = useIntl();
    return (
        <Stack
            gap={1.5}
            display={hidden ? "none" : "flex"}
            component="form"
            onSubmit={async (e) => {
                e.preventDefault();
                setLoading(true);
                await onSubmit(text).finally(() => {
                    setLoading(false);
                    setText("");
                });
            }}
        >
            <TextField
                onChange={(e) => setText(e.target.value)}
                value={text}
                fullWidth
                minRows={3}
                multiline
                placeholder={formatMessage({ defaultMessage: "Add a new note here" })}
                slotProps={{
                    htmlInput: {
                        autosize: true,
                        style: {
                            resize: "vertical",
                        },
                    },
                }}
                {...inputProps}
            />

            <Stack direction="row-reverse" gap={1.5} alignItems="center">
                <LoadingButton
                    size="small"
                    type="submit"
                    loading={loading}
                    disabled={text.length === 0}
                    sx={{ alignSelf: "end" }}
                >
                    {saveLabel}
                </LoadingButton>
                {onCancel && (
                    <Button color="secondary" size="small" onClick={onCancel}>
                        <FormattedMessage defaultMessage="Cancel" />
                    </Button>
                )}
            </Stack>
        </Stack>
    );
};

export const NotesV2 = ({ notes, onCreate, onDelete, onUpdate }: Props) => {
    const session = useSessionContext();

    const [text, setText] = React.useState("");

    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

    // when user clicks on the menu for a note, we'll store the note id and text in this state
    const [focusedNote, setFocusedNote] = useState<{ id: string; text: string } | null>(null);

    // whether the user clicked "Edit note" in the menu
    const [isEditing, setIsEditing] = React.useState(false);
    const { formatMessage } = useIntl();

    function handleClose() {
        setAnchorEl(null);
    }

    return (
        <>
            <Stack gap={2.5}>
                {/* comment section */}
                <Stack gap={2.5} maxHeight="400px" overflow="auto">
                    {notes.map((note) => (
                        <Stack justifyItems="stretch" gap={1} key={note.id}>
                            <Stack direction="row" alignItems="center">
                                <UserRow
                                    key={note.id}
                                    userId={note.creator.id}
                                    subsection={dayjs(note.createdAt).fromNow()}
                                />
                                {session.id === note.creator.id && (
                                    <IconButton
                                        onClick={(e) => {
                                            setAnchorEl(e.currentTarget);
                                            setFocusedNote({ id: note.id, text: note.text });
                                        }}
                                        sx={{ ml: "auto" }}
                                        size="xsmall"
                                        color="ghostGray"
                                    >
                                        <DotsVertical />
                                    </IconButton>
                                )}
                            </Stack>
                            {focusedNote?.id === note.id && isEditing ? (
                                <AddNoteSection
                                    initial={focusedNote.text}
                                    onSubmit={async (text) => {
                                        await onUpdate(focusedNote.id, text);
                                        setFocusedNote(null);
                                        setIsEditing(false);
                                    }}
                                    onCancel={() => {
                                        setFocusedNote(null);
                                        setIsEditing(false);
                                    }}
                                    saveLabel={formatMessage({ defaultMessage: "Save changes" })}
                                />
                            ) : (
                                <Typography variant="textSm" fontWeight={400}>
                                    {note.text}
                                </Typography>
                            )}
                        </Stack>
                    ))}
                </Stack>
                {/* add new note section */}
                <AddNoteSection
                    hidden={isEditing}
                    initial={text}
                    onSubmit={async (text) => {
                        await onCreate(text).finally(() => {
                            setText("");
                        });
                    }}
                    saveLabel={formatMessage({ defaultMessage: "Add note" })}
                />
            </Stack>
            <RiddleMenu
                anchorEl={anchorEl}
                onClose={() => handleClose()}
                entries={[
                    {
                        label: formatMessage({ defaultMessage: "Edit note" }),
                        icon: { pos: "start", component: <Pen /> },
                        onClick: () => {
                            setIsEditing(true);
                            setAnchorEl(null);
                        },
                    },
                    {
                        label: formatMessage({ defaultMessage: "Delete note" }),
                        danger: true,
                        icon: { pos: "start", component: <Trash /> },
                        onClick: async () => {
                            if (focusedNote?.id) {
                                await onDelete(focusedNote.id);
                            }
                            handleClose();
                        },
                    },
                ]}
            />
        </>
    );
};

import { ChartColumn, InformationCircle, Lock, PenSolid, Users, UserShield } from "@ignite-analytics/icons";
import { Avatar, AvatarGroup, Button, Divider, IconButton, Popover, Stack, Tooltip, Typography } from "@mui/material";
import { useMemo, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { Contact } from "../ui/ContactList/contactList";
import messages from "./messages";

export type Access = {
    contact: Contact; // User | Group possibly eventually
    justification:
        | { reason: "role"; role: "global.admin" | "global.editor" }
        | { reason: "individual-grant" }
        | { reason: "contract-responsible" };
};

const ADMIN_ROLE = "Administrator role";
const CONTRACT_RESPONSIBLE = "Contract responsible";
const INDIVIDUAL_PERMISSIONS = "Individual permissions";

const Justification = ({ access }: { access: Access }) => {
    const { formatMessage } = useIntl();
    if (access.justification.reason === "role") {
        const translatedRoe = formatMessage(messages[access.justification.role] ?? messages.unknown);
        return <FormattedMessage defaultMessage="Has {role} role" values={{ role: translatedRoe }} />;
    }
    if (access.justification.reason === "individual-grant") {
        return <FormattedMessage defaultMessage="Granted individual access" />;
    }
    if (access.justification.reason === "contract-responsible") {
        return <FormattedMessage defaultMessage="Contract responsible" />;
    }
    return null;
};

type UserAvatarProps = {
    access: Access;
    displayName?: boolean;
    displayTooltip?: boolean;
};

const UserAvatar = ({ access, displayName = false, displayTooltip = false }: UserAvatarProps) => {
    let initials = "";
    let display = "";
    if (access.contact.firstName) {
        [initials] = access.contact.firstName;
        display = access.contact.firstName;
    }
    if (access.contact.lastName) {
        initials += access.contact.lastName[0];
        display += " " + access.contact.lastName; // eslint-disable-line prefer-template
    }
    if (!display) {
        // use email
        display = access.contact.email;
    }

    let component = (
        <Avatar
            sx={{
                width: 32,
                height: 32,
            }}
            src={access.contact.profilePicture}
            alt={display}
        >
            <Typography variant="textSm">{initials}</Typography>
        </Avatar>
    );

    if (displayName) {
        component = (
            <Stack alignItems="center" direction="row" gap={1}>
                {component}
                <Stack direction="column">
                    <Typography variant="subtitle2">{display}</Typography>
                </Stack>
            </Stack>
        );
    }

    if (displayTooltip) {
        const tooltipContent = (
            <Stack direction="column">
                <Typography variant="subtitle2">{display}</Typography>
                <Typography variant="caption">
                    <Justification access={access} />
                </Typography>
            </Stack>
        );
        component = (
            <Tooltip title={tooltipContent} placement="bottom">
                {component}
            </Tooltip>
        );
    }

    return component;
};

const ContractPublic = () => (
    <Stack direction="row" alignItems="center" spacing={1}>
        <InformationCircle fontSize="small" />
        <Stack>
            <Typography variant="body2">
                <FormattedMessage defaultMessage="This contract is public" />
            </Typography>
            <Typography variant="caption" color="text.secondary">
                <FormattedMessage defaultMessage="It can be seen by all users with view access." />
            </Typography>
        </Stack>
    </Stack>
);

const ContractRestricted = () => (
    <Stack direction="row" alignItems="center" spacing={1}>
        <Lock fontSize="small" />
        <Stack>
            <Typography variant="body2">
                <FormattedMessage defaultMessage="This contract is restricted" />
            </Typography>
            <Typography variant="caption" color="text.secondary">
                <FormattedMessage defaultMessage="It can only be seen by administrators, a contract responsible, and individuals with granted access." />
            </Typography>
        </Stack>
    </Stack>
);

const GroupTitleMessage = ({ id: groupName }: { id: string }) => {
    if (groupName === ADMIN_ROLE) {
        return (
            <Stack direction="row" alignItems="center" spacing={1} gap={1}>
                <UserShield fontSize="small" />
                <FormattedMessage defaultMessage="Administrator role" />
                <Divider />
            </Stack>
        );
    }
    if (groupName === CONTRACT_RESPONSIBLE) {
        return (
            <Stack direction="row" alignItems="center" spacing={1} gap={1}>
                <ChartColumn fontSize="small" />
                <FormattedMessage defaultMessage="Contract responsible" />
                <Divider />
            </Stack>
        );
    }
    if (groupName === INDIVIDUAL_PERMISSIONS) {
        return (
            <Stack direction="row" alignItems="center" spacing={1} gap={1}>
                <Users fontSize="small" />
                <FormattedMessage defaultMessage="Individual permissions" />
                <Divider />
            </Stack>
        );
    }
};

const GroupTitle = ({ text }: { text: string }) => (
    <Stack direction="row" alignItems="center" spacing={1} pt={1.25}>
        <Typography variant="textMd">
            <GroupTitleMessage id={text} />
        </Typography>
    </Stack>
);

type Props = {
    includeHeader?: boolean;
    restricted: boolean;
    accessors: Access[];
    max?: number;
    canEdit?: boolean;
    onEdit?: () => void;
};

const NotAssigned = () => (
    <Stack direction="row" alignItems="center" spacing={1}>
        <Typography variant="textSm" color="textTextHelper">
            <FormattedMessage defaultMessage="Not assigned" />
        </Typography>
    </Stack>
);

const SurplusPopover = ({
    id,
    accessors,
    open,
    handleClose,
    elem,
}: {
    id: string | undefined;
    accessors: Access[];
    open: boolean;
    handleClose: any;
    elem: any;
}) => {
    const byRole = accessors.filter((access) => access.justification.reason === "role");
    const byContractResponsible = accessors.filter((access) => access.justification.reason === "contract-responsible");
    const byIndividual = accessors.filter((access) => access.justification.reason === "individual-grant");
    return (
        <Popover
            open={open}
            id={id}
            onClose={handleClose}
            anchorEl={elem}
            anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
            sx={{ py: 1 }}
        >
            <Stack gap={1} sx={{ minWidth: 300, maxHeight: 400, overflowX: "auto", px: 2, py: 2 }}>
                <Typography>
                    <FormattedMessage defaultMessage="Additional accessors" />
                </Typography>
                <GroupTitle text={ADMIN_ROLE} />
                {byRole.length > 0 ? (
                    byRole.map((access) => <UserAvatar displayName key={access.contact.id} access={access} />)
                ) : (
                    <NotAssigned />
                )}
                <GroupTitle text={CONTRACT_RESPONSIBLE} />
                {byContractResponsible.length > 0 ? (
                    byContractResponsible.map((access) => (
                        <UserAvatar displayName key={access.contact.id} access={access} />
                    ))
                ) : (
                    <NotAssigned />
                )}
                <GroupTitle text={INDIVIDUAL_PERMISSIONS} />
                {byIndividual.length > 0 ? (
                    byIndividual.map((access) => <UserAvatar displayName key={access.contact.id} access={access} />)
                ) : (
                    <NotAssigned />
                )}
            </Stack>
        </Popover>
    );
};

export const AccessOverview: React.FC<Props> = ({ includeHeader, canEdit, onEdit, restricted, accessors, max = 4 }) => {
    const surplus = useMemo(() => accessors.length - max, [accessors, max]);
    const [surplusPopover, setSurplusPopoverOpen] = useState(false);
    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
    const accessPopoverId = surplusPopover ? "access-popover" : undefined;

    const handleClick = (event: React.MouseEvent<any>) => {
        setSurplusPopoverOpen(true);
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setSurplusPopoverOpen(false);
        setAnchorEl(null);
    };

    return (
        <Stack direction="column" spacing={2}>
            {includeHeader && (
                <Stack direction="row" alignItems="center" justifyContent="space-between">
                    <Typography variant="h6">
                        <FormattedMessage defaultMessage="Who can view this contract?" />
                    </Typography>
                    {canEdit && (
                        <IconButton size="small" onClick={onEdit}>
                            <PenSolid fontSize="inherit" sx={{ mr: 0.5 }} />
                            <Typography>
                                <FormattedMessage defaultMessage="Edit" />
                            </Typography>
                        </IconButton>
                    )}
                </Stack>
            )}
            {restricted ? (
                <>
                    <Stack direction="row" alignItems="center" spacing={1}>
                        <AvatarGroup spacing={1}>
                            {accessors.slice(0, max).map((access) => (
                                <UserAvatar displayTooltip key={access.contact.id} access={access} />
                            ))}
                        </AvatarGroup>
                        {surplus > 0 && (
                            <Button
                                variant="text"
                                color={surplusPopover ? "primary" : "secondary"}
                                onClick={handleClick}
                                aria-describedby={accessPopoverId}
                            >
                                <Typography variant="caption" sx={{ cursor: "default" }}>
                                    <FormattedMessage defaultMessage="+{surplus} more" values={{ surplus }} />
                                </Typography>
                            </Button>
                        )}
                        <SurplusPopover
                            id={accessPopoverId}
                            accessors={accessors.slice(max, accessors.length)}
                            open={surplusPopover}
                            handleClose={handleClose}
                            elem={anchorEl}
                        />
                    </Stack>
                    <ContractRestricted />
                </>
            ) : (
                <ContractPublic />
            )}
        </Stack>
    );
};

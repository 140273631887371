import { useLazyQuery } from "@apollo/client";
import { useFeatureToggle } from "@ignite-analytics/feature-toggle";
import { MessageCircleBlank } from "@ignite-analytics/icons";
import {
    Chip,
    CircularProgress,
    FormControl,
    IconButton,
    InputAdornment,
    InputLabel,
    MenuItem,
    Paper,
    Select,
    Stack,
    TextField,
    Typography,
} from "@mui/material";
import { useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { graphql } from "@/generated";
import { Attachment } from "../FileAttachments/hooks";

const askDocumentQuestionDocument = graphql(`
    query contracts_askDocumentQuestion($input: AskDocumentQuestionRequest!) {
        askDocumentQuestion(input: $input) {
            answer
        }
    }
`);

type Props = {
    availableDocuments: Attachment[];
};
export const AskDocumentQuestion: React.FC<Props> = ({ availableDocuments }) => {
    const [selectedFileUrl, setSelectedFileUrl] = useState<string | null>(
        availableDocuments.length ? availableDocuments[0].url : null
    );
    const [question, setQuestion] = useState("");
    const [askDocumentQuestion, { data, loading, error, called }] = useLazyQuery(askDocumentQuestionDocument);

    const { formatMessage } = useIntl();

    const isFeatureToggled = useFeatureToggle("contracts-ask-document-questions-poc");

    const handleAskQuestion = () => {
        if (!selectedFileUrl) {
            return;
        }
        askDocumentQuestion({
            variables: {
                input: {
                    fileURL: selectedFileUrl,
                    question,
                },
            },
        });
    };

    if (!isFeatureToggled) {
        return null;
    }
    return (
        <Stack gap={2} pt={2}>
            <Stack direction="row" justifyContent="space-between">
                <Typography variant="textMd">
                    <FormattedMessage defaultMessage="Ask your documents a question" />
                </Typography>
                <Chip color="ignite" label={formatMessage({ defaultMessage: "Experimental" })} />
            </Stack>
            <FormControl>
                <InputLabel id="document-select-label">
                    <FormattedMessage defaultMessage="Select document" />
                </InputLabel>
                <Select
                    value={selectedFileUrl}
                    labelId="document-select-label"
                    label={formatMessage({ defaultMessage: "Select document" })}
                    onChange={(e) => {
                        setSelectedFileUrl(e.target.value);
                    }}
                >
                    {availableDocuments.map((doc) => (
                        <MenuItem key={doc.url ?? ""} value={doc.url ?? ""}>
                            {doc.name}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
            <TextField
                label={formatMessage({ defaultMessage: "Ask a question" })}
                placeholder={formatMessage({ defaultMessage: "Summarize the contract" })}
                value={question}
                onChange={(e) => setQuestion(e.target.value)}
                InputProps={{
                    endAdornment: (
                        <InputAdornment position="end">
                            <IconButton onClick={handleAskQuestion}>
                                <MessageCircleBlank />
                            </IconButton>
                        </InputAdornment>
                    ),
                }}
            />
            <Paper sx={{ padding: 2 }}>
                {loading && (
                    <Stack alignItems="center">
                        <CircularProgress />
                    </Stack>
                )}
                {error && (
                    <Typography variant="textSm" color="error">
                        {error.message}
                    </Typography>
                )}
                {data && (
                    <Typography variant="textSm" color="textTextHelper">
                        {data.askDocumentQuestion.answer}
                    </Typography>
                )}
                {!called && (
                    <Typography variant="textSm" color="textTextHelper">
                        <FormattedMessage defaultMessage="Ask a question to get an answer" />
                    </Typography>
                )}
            </Paper>
        </Stack>
    );
};

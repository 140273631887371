import { Tooltip } from "@mui/material";
import { AutocompleteRenderGetTagProps } from "@mui/material/Autocomplete";
import Chip from "@mui/material/Chip";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import type { Contact } from "src/entities/users";
import DropDown from "../DropDown/Combobox";

const ContactOption = ({ firstName, lastName, email }: Contact) => (
    <Stack>
        <Typography variant="body1">
            {firstName} {lastName}
        </Typography>
        <Stack>
            <Typography>{email}</Typography>
        </Stack>
    </Stack>
);

const toSearchableText = ({ firstName, lastName, email }: Contact) => `${firstName} ${lastName} ${email}`;

type Props = {
    label?: string;
    readonly?: boolean;
    multiple?: boolean;
    defaultValue: Contact[];
    options: Contact[];
    placeholder?: string;
    isOptionEqualToValue?: (option: Contact, value: Contact) => boolean;
    onBlur?: () => void;
    onChange: (values: Contact[]) => void;
    onCreate?: (input: string) => void;
};

const getInitials = (firstName: string | undefined, lastName: string | undefined) => {
    return `${firstName?.[0] ?? ""}${lastName?.[0] ?? ""}`;
};
export const ContactsDropdown = ({
    readonly,
    multiple,
    onBlur,
    defaultValue,
    options,
    placeholder,
    isOptionEqualToValue,
    onChange,
    onCreate,
}: Props) => (
    <DropDown
        size="small"
        multiple={multiple}
        defaultValue={defaultValue}
        options={options}
        getOptionLabel={toSearchableText}
        loading={false}
        optionTemplateComponent={ContactOption}
        isOptionEqualToValue={isOptionEqualToValue}
        onChange={onChange}
        onBlur={onBlur}
        readOnly={readonly}
        onCreate={onCreate}
        placeholder={placeholder}
        renderTags={(values: Contact[], getTagProps: AutocompleteRenderGetTagProps) =>
            values.map((option, index) => (
                <Tooltip key={option.id} title={option.email}>
                    <Chip
                        {...getTagProps({ index })}
                        color="infoGray"
                        label={
                            <Stack direction="row" gap={1} alignItems="flex-end">
                                <Typography variant="textXs" color="textTextBody" fontWeight={500}>
                                    {getInitials(option.firstName, option.lastName)}
                                </Typography>
                                <Typography variant="textSm">{`${option.firstName} ${option.lastName}`}</Typography>
                            </Stack>
                        }
                    />
                </Tooltip>
            ))
        }
    />
);

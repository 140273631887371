import { Avatar, AvatarGroup, Stack, Tooltip, Typography } from "@mui/material";
import { User } from "@/entities/users";
import { UserItem } from "../ContractsTablePro/renderCustomField/UserCell/UserItem";
import { UserSurplus } from "../ContractsTablePro/renderCustomField/UserCell/UserSurplus";
import { UserRow } from "./Row";

type Props = {
    users: User[];
    max?: number;
};

export const ContractResponsibleDisplay = ({ users, max = 5 }: Props) => {
    if (users.length === 1) {
        return <UserRow userId={users[0].id} />;
    }
    return (
        <Stack direction="row">
            <AvatarGroup
                max={max}
                spacing={1}
                renderSurplus={() => <UserSurplus textVariant="textSm" users={users.slice(max - 1)} />}
                slotProps={{ surplus: { sx: { width: 32, height: 32 } } }}
            >
                {users.map((user) => (
                    <Tooltip key={user.id} title={<UserItem user={user} />}>
                        <Avatar src="" sx={{ width: 32, height: 32 }}>
                            <Typography variant="textSm">{`${user.firstName[0]}${user.lastName[0]}`}</Typography>
                        </Avatar>
                    </Tooltip>
                ))}
            </AvatarGroup>
        </Stack>
    );
};

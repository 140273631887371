import * as Sentry from "@sentry/react";
import { Alert, AlertTitle, Button, Stack, Typography } from "@mui/material";
import { ErrorComponentProps } from "@tanstack/react-router";
import React, { useEffect } from "react";
import { Heart } from "@ignite-analytics/icons";

import { fm } from "@/contexts/IntlContext";
import messages from "./messages";

const FatalErrorFallback: React.FC<ErrorComponentProps> = ({ error }) => {
    useEffect(() => {
        console.log("Capturing exception", error);
        Sentry.captureException(error, { tags: { app: "contracts-app" } });
    }, [error]);
    return (
        <Stack direction="row" alignItems="center" justifyContent="center" sx={{ width: "100%", height: "80vh" }}>
            <Alert
                severity="error"
                sx={{ textAlign: "center", alignItems: "center", justifyContent: "center", width: "60%" }}
                icon={false}
            >
                <Stack direction="column" alignItems="center" justifyContent="center" gap={2}>
                    <Stack direction="row" alignItems="center" justifyContent="center" gap={2}>
                        <Heart fontSize="large" sx={{ marginBottom: "10px" }} />
                        <AlertTitle sx={{ fontSize: 38 }}>{fm(messages.error)}</AlertTitle>
                    </Stack>
                    <Typography sx={{ fontSize: 22 }}>{fm(messages.somethingWentWrong)}</Typography>
                    <Button size="large" onClick={() => window.location.reload()}>
                        {fm(messages.refresh)}
                    </Button>
                </Stack>
            </Alert>
        </Stack>
    );
};

export default FatalErrorFallback;

import { Stack, Typography } from "@mui/material";

interface HighlightedLabelProps {
    label: string;
    searchTerm?: string;
}

const HighlightedLabel: React.FC<HighlightedLabelProps> = ({ label, searchTerm = "" }) => {
    const getHighlightedText = (text: string, highlight: string): React.ReactNode => {
        if (!highlight) return text;

        const lowerText = text.toLowerCase();
        const lowerHighlight = highlight.toLowerCase();
        const startIndex = lowerText.indexOf(lowerHighlight);

        if (startIndex === -1) return text;

        const beforeMatch = text.slice(0, startIndex);
        const match = text.slice(startIndex, startIndex + highlight.length);
        const afterMatch = text.slice(startIndex + highlight.length);

        return (
            <>
                {beforeMatch}
                <Typography component="span" fontWeight="bold">
                    {match}
                </Typography>
                {afterMatch}
            </>
        );
    };

    return (
        <Stack justifyContent="space-between" direction="row" padding={0.75} spacing={1}>
            <Typography variant="textSm">{getHighlightedText(label, searchTerm)}</Typography>
        </Stack>
    );
};

export default HighlightedLabel;

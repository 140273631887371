import { Phone } from "@ignite-analytics/icons";
import { Link, List, ListItem, ListItemIcon, ListItemText, Typography } from "@mui/material";
import Avatar from "@mui/material/Avatar";
import type { Contact } from "src/entities/users";

type Props = {
    contacts: Contact[];
    horizontal?: boolean;
    emptyInfoText?: string;
    showPhone?: true;
    secondaryAction?: (contact: Contact) => JSX.Element;
};

type ListItemProps = {
    contact: Contact;
    horizontal?: boolean;
    showPhone?: true;
    secondaryAction?: JSX.Element;
};

const horizontalListCss = { display: "flex", flexWrap: "wrap", flexDirection: "row", padding: 0 };
const horizontalItemCss = { marginRight: "10px", width: "auto" };

const getInitials = (contact: Contact) => {
    return `${contact.firstName?.[0] ?? ""}${contact.lastName?.[0] ?? ""}`;
};
const ContactListItem = ({ contact, horizontal, showPhone, secondaryAction }: ListItemProps) => (
    <ListItem
        style={horizontal ? horizontalItemCss : {}}
        key={contact.id}
        aria-label={`${contact.firstName} ${contact.lastName} `}
        sx={{ paddingLeft: 0, py: 0.25 }}
        secondaryAction={secondaryAction}
    >
        <ListItemIcon title={`${contact.firstName} ${contact.lastName} `}>
            <Avatar alt={`${contact.firstName} ${contact.lastName} `} src={contact.profilePicture}>
                {getInitials(contact)}
            </Avatar>
        </ListItemIcon>
        <ListItemText
            primary={`${contact.firstName} ${contact.lastName} `}
            secondaryTypographyProps={{ style: { whiteSpace: "normal" } }}
            secondary={
                <span style={{ width: "100%" }}>
                    <Typography variant="textSm" color="textTextBody">
                        {contact.email}
                    </Typography>
                    <br />
                    {showPhone && (
                        <>
                            <Link href={contact.phone ? `callto:${contact.phone} ` : undefined}>
                                <Phone sx={{ fontSize: "1rem", marginRight: ".25rem", marginBottom: "-.2rem" }} />
                            </Link>
                            {contact.phone ? contact.phone : "N/A"}
                        </>
                    )}
                </span>
            }
        />
    </ListItem>
);
export const ContactsList = ({ contacts, horizontal, emptyInfoText, showPhone, secondaryAction }: Props) =>
    contacts.length ? (
        <List dense={horizontal} sx={horizontal ? horizontalListCss : {}}>
            {contacts.map((contact) => (
                <ContactListItem
                    contact={contact}
                    horizontal={horizontal}
                    showPhone={showPhone}
                    secondaryAction={secondaryAction?.(contact)}
                    key={`contact - list - item - ${contact.id} `}
                />
            ))}
        </List>
    ) : (
        <Typography variant="body1">{emptyInfoText}</Typography>
    );

export { Contact };

import { useSessionContext } from "@/contexts/SessionContext";
import { captureMessage } from "@/errorHandling/errors";
import { useContractsApiFetch as get } from "@/hooks/useContractsApiFetch";

export type Attachment = {
    id: string;
    name: string;
    // url can be null if it's not available for the current user
    url: string | null;
    createdAt: number;
    isPublic: boolean;
    metadata?: { [key: string]: string };
};

export function useAttachments(id: string) {
    const skip = id === "";
    const { data, isLoading: loading, refetch } = get<Attachment[]>(`contracts/${id}/files`, skip);
    const session = useSessionContext();
    return {
        data: data ?? [],
        loading,
        refetch,
        upload: async (file: File) => {
            const { name, type } = file;
            // replace special characters with underscore. Otherwise a filename like this would fail:
            // "Kontrakt\ #1.pdf"
            // we could alternatively use the [^a-zA-Z0-9] regex to match all non-alphanumeric characters
            // but that would not work well for filenames containing Norwegian / Swedish characters, which
            // is probably common.
            const sanitizedFileName = name.replace(/[&/\\#,+()$~%'":*?<>{}]/g, "_");
            const res = await fetch(
                `${import.meta.env.REACT_APP_CONTRACTS_BASE_URL}/contracts/${id}/files/${sanitizedFileName}/signedUrl`,
                {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify({
                        contentType: type,
                    }),
                    credentials: "include",
                }
            );
            if (res.status !== 200) {
                captureMessage("Failed to get signed URL for file upload", { tags: { contractId: id } });
                return;
            }
            const { url } = (await res.json()) as { url: string };

            const res2 = await fetch(url, {
                method: "PUT",
                body: file,
                headers: {
                    "Content-Type": type,
                },
            });
            if (res2.status !== 200) {
                captureMessage("Failed to upload file", { tags: { contractId: id } });
            }
            refetch();
        },
        toggleVisibility: async (fileId: string, isPublic: boolean) => {
            const res = await fetch(`${import.meta.env.REACT_APP_CONTRACTS_BASE_URL}/contracts/${id}/files/${fileId}`, {
                method: "PATCH",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    value: isPublic,
                    operation: "replace",
                    path: "/isPublic",
                }),
                credentials: "include",
            });
            if (res.status !== 204) {
                captureMessage("Failed to update file visibility", { tags: { contractId: id } });
            }
        },
        delete: async (fileId: string) => {
            const res = await fetch(`${import.meta.env.REACT_APP_FILES_SERVICE_BASE_URL}/api/files/${fileId}`, {
                method: "DELETE",
                credentials: "include",
                headers: {
                    "x-tenant-id": session.tenant,
                },
            });
            if (res.status !== 204) {
                captureMessage("Failed to delete file", { tags: { contractId: id } });
            }
            refetch();
        },
    };
}

import { Plus } from "@ignite-analytics/icons";
import { Chip, Stack, Typography } from "@mui/material";


type Props = {
    suggestion: string;
    disabled: boolean;
    onClick: (suggestion: string) => void;
}
export const PromptSuggestionChip: React.FC<Props> = ({suggestion, disabled, onClick}) => (
    <Stack direction="row" justifyContent="end">
        <Chip
            color="infoGray"
            sx={{
                cursor: disabled ? "not-allowed" : "pointer",
                height: "auto",
                "&:hover": {
                    backgroundColor: (t) => t.palette.grey[300],
                },
            }}
            onClick={() => !disabled && onClick(suggestion)}
            label={
                <Stack direction="row" py={0.5} gap={1} alignItems="center">
                    <Plus color="secondary" />
                    <Typography variant="textSm" sx={{ whiteSpace: "normal" }} color="textDisabled">
                        {suggestion}
                    </Typography>
                </Stack>
            }
        />
    </Stack>
);
import { track } from "@ignite-analytics/track";
import { useEffect, useMemo, useState } from "react";
import { useContacts } from "src/entities/users";
import { useErrorHandler } from "src/errorHandling/ErrorHandlerContext";
import { useContractsApiPost } from "src/hooks/useContractsApiPost";
import { makeContractsApiHttp } from "src/http";
import { useIntl } from "react-intl";
import { Access, EntitledUser, ManageAccessPopup as Popup } from "./dumb/manageAccessPopup";
import { useSnackbar } from "@/contexts/useSnackbar";

type Props = {
    contractIds: string[];
    defaultAccess: Access;
    isOpen: boolean;
    onClose: () => void;
    updateContracts: (contractIds: string[], access: Access) => void;
};

export type ContractPermissions = {
    objectId: string;
    entitleds: EntitledUser[];
};

export const ManageAccessPopup = ({ contractIds, defaultAccess, isOpen, onClose, updateContracts }: Props) => {
    const [contractsPermissions, setContractsPermissions] = useState<ContractPermissions[]>([]);
    const updatePermissions = useContractsApiPost();
    const { handleError } = useErrorHandler();
    const { postSnackbar } = useSnackbar();
    const { formatMessage } = useIntl();
    const http = useMemo(() => makeContractsApiHttp(), []);
    const { loading, contacts } = useContacts();
    const onSave = (newPermissions: ContractPermissions[], access: Access) => {
        newPermissions.forEach((command) => {
            updatePermissions<ContractPermissions>(`contracts/object-permissions`, command).catch(handleError);
        });

        track("ManageAccessPopup save", { contracts: newPermissions.length });

        http.patch(`/contracts/access/bulk`, {
            operations: contractIds.map((contractId) => ({
                path: `${contractId}/isPrivate`,
                operation: "replace",
                value: access === "restricted",
            })),
        })
            .then(() => {
                onClose();
                updateContracts(contractIds, access);
                postSnackbar({ message: formatMessage({ defaultMessage: "Access was successfully updated" }) });
            })
            .catch(handleError);
    };

    const contractIdQueryParam = (id: string) => `contractId=${id}`;

    useEffect(() => {
        if (isOpen) {
            http.get<ContractPermissions[]>(
                `contracts/object-permissions?${contractIds.map((n) => contractIdQueryParam(n)).join("&")}`
            )
                .then(({ data }) => {
                    setContractsPermissions(data);
                })
                .catch((err) => handleError(err));
        }
    }, [contractIds, handleError, isOpen, http]);

    if (loading) return null;
    return (
        <Popup
            isOpen={isOpen}
            contacts={contacts}
            contractsPermission={contractsPermissions}
            defaultAccess={defaultAccess}
            onClose={onClose}
            onSave={onSave}
            contractsIds={contractIds}
        />
    );
};

import { track } from "@ignite-analytics/track";
import { Tooltip } from "@mui/material";
import {
    DataGridPro,
    DataGridProProps,
    GRID_CHECKBOX_SELECTION_COL_DEF,
    GRID_CHECKBOX_SELECTION_FIELD,
    GridApiPro,
    GridCallbackDetails,
    GridCellCheckboxRenderer,
    GridColumnOrderChangeParams,
    GridColumnVisibilityModel,
    GridDensity,
    GridRenderCellParams,
    GridRowParams,
    GridRowSelectionModel,
    GridSortDirection,
    GridSortItem,
} from "@mui/x-data-grid-pro";
import { MutableRefObject, useCallback, useMemo } from "react";
import { FormattedMessage } from "react-intl";
import { makeMessages } from "src/components/ContractsTablePro";
import { hasValue } from "src/helpers/hasValue";
import { ColumnHeader } from "./column";
import { RIGHT_PINNED_COLUMNS } from "./constants";
import { Row } from "./contractListItem";
import { headerToMuiColumn } from "./headerToMuiColumn";
import { customDataGridStyling } from "./tableStyling";
import { ContractsTableToolbar, ContractsTableToolbarProps } from "./Toolbar";

declare module "@mui/x-data-grid-pro" {
    interface ToolbarPropsOverrides extends ContractsTableToolbarProps {}
}

export interface ContractsTableProps {
    headers: ColumnHeader[];
    rows: Row[];
    sortBy: string | undefined;
    sortDirection: GridSortDirection;
    orderedColumnsIds: string[];
    pageSize: number;
    loading: boolean;
    density: GridDensity;
    currentPage: number;
    totalNumberOfRows: number;
    checkboxSelection: boolean;
    columnVisibilityModel: GridColumnVisibilityModel;
    pinnedColumns: string[];
    rowSelectionModel: GridRowSelectionModel;
    onColumnsOrderChanged: (headersIds: string[]) => void;
    onColumnWidthChanged: (coldId: string, width: number) => void;
    onColumnVisibilityModelChange: (model: GridColumnVisibilityModel) => void;
    onSortChange: (sortBy: string | undefined, sortDirection: GridSortDirection) => void;
    onPaginationChange: (p: { page: number; pageSize: number }) => void;
    onRowSelectionModelChange:
        | ((rowSelectionModel: GridRowSelectionModel, details: GridCallbackDetails) => void)
        | undefined;
    isRowSelectable: ((params: GridRowParams<Row>) => boolean) | undefined;
    checkboxSelectionVisibleOnly: boolean | undefined;
    apiRef: MutableRefObject<GridApiPro> | undefined;
    tableToolbarProps: ContractsTableToolbarProps;
}

// TODO: Style table in theme, and remove these **********
const defaultSlotProps: DataGridProProps["slotProps"] = {
    baseButton: {
        color: "secondary",
        size: "small",
    },
};

const gridCellCheckboxColumn = {
    ...GRID_CHECKBOX_SELECTION_COL_DEF,
    field: GRID_CHECKBOX_SELECTION_FIELD,
    fieldType: "checkbox",
    renderCell: (params: GridRenderCellParams) => {
        const isRowSelectable = params.api.isRowSelectable(params.id);
        if (!isRowSelectable) {
            return (
                <Tooltip
                    placement="left"
                    title={
                        <FormattedMessage
                            defaultMessage="Only administrators or contract responsible with editor access can modify this contract."
                            description="Tooltip for disabled checkbox in contracts table"
                        />
                    }
                >
                    <div>
                        <GridCellCheckboxRenderer {...params} />
                    </div>
                </Tooltip>
            );
        }
        return <GridCellCheckboxRenderer {...params} />;
    },
} as const;

export const TablePro = ({
    rows,
    rowSelectionModel,
    onRowSelectionModelChange,
    isRowSelectable,
    checkboxSelectionVisibleOnly,
    headers,
    columnVisibilityModel,
    onColumnVisibilityModelChange,
    onSortChange,
    pageSize,
    density,
    loading,
    currentPage,
    orderedColumnsIds,
    checkboxSelection,
    onColumnWidthChanged,
    onPaginationChange,
    onColumnsOrderChanged,
    totalNumberOfRows,
    apiRef,
    sortBy,
    sortDirection,
    pinnedColumns,
    tableToolbarProps,
}: ContractsTableProps) => {
    const messages = makeMessages();
    const orderedHeaders = useMemo(() => {
        const newHeaders = headers.filter((header) => !orderedColumnsIds.includes(header.id));
        const orderedHeaders = orderedColumnsIds.map((id) => headers.find((h) => h.id === id)).filter(hasValue);

        return [...newHeaders, ...orderedHeaders];
    }, [headers, orderedColumnsIds]);

    const columns = useMemo(() => {
        const columns = orderedHeaders.map((header) => headerToMuiColumn(header));
        return [gridCellCheckboxColumn, ...columns];
    }, [orderedHeaders]);

    const sortModel = useMemo(() => {
        if (!sortBy) return [];
        return [{ field: sortBy, sort: sortDirection }];
    }, [sortBy, sortDirection]);

    const mergedSlotProps = useMemo(() => {
        const slotProps: DataGridProProps["slotProps"] = {
            ...defaultSlotProps,
            pagination: {
                page: currentPage,
                count: totalNumberOfRows,
                rowsPerPage: pageSize,
                rowsPerPageOptions: [25, 50, 100],
                onPageChange: (_, page) => {
                    onPaginationChange({ page, pageSize });
                },
                onRowsPerPageChange: (e) => {
                    const pageSize = parseInt(e.target.value, 10);
                    onPaginationChange({ page: 0, pageSize });
                },
            },
            toolbar: {
                ...tableToolbarProps,
            },
        };
        return slotProps;
    }, [currentPage, pageSize, totalNumberOfRows, onPaginationChange, tableToolbarProps]);

    const handleColumnOrderChange = useCallback(
        (event: GridColumnOrderChangeParams) => {
            const { targetIndex } = event;
            if (!apiRef) return;
            const orderedColumns = apiRef.current.getAllColumns();
            const newOrderedIds = orderedColumns.map((col) => col.field);
            onColumnsOrderChanged(newOrderedIds);

            // Track reordering columns
            const movedColDef = orderedColumns[targetIndex];
            if (!movedColDef) return;
            const movedColumn = columns.find((col) => col.field === movedColDef.field);
            if (!movedColumn) return;
            track("Contracts List: Reorder column", {
                newIndex: targetIndex,
                columnId: movedColumn.field,
                columnType: movedColumn.fieldType,
                columnLabel: movedColumn.headerName,
            });
        },
        [apiRef, columns, onColumnsOrderChanged]
    );

    return (
        <DataGridPro
            apiRef={apiRef}
            slotProps={mergedSlotProps}
            slots={{
                toolbar: ContractsTableToolbar,
            }}
            checkboxSelection={checkboxSelection}
            rowSelectionModel={rowSelectionModel}
            onRowSelectionModelChange={onRowSelectionModelChange}
            isRowSelectable={isRowSelectable}
            checkboxSelectionVisibleOnly={checkboxSelectionVisibleOnly}
            showColumnVerticalBorder
            showCellVerticalBorder
            pagination
            sx={customDataGridStyling}
            loading={loading}
            density={density}
            localeText={messages.muiMessages}
            rowCount={totalNumberOfRows}
            disableColumnFilter
            onSortModelChange={(sortModel) => {
                const sort: GridSortItem | undefined = sortModel[0];
                onSortChange(sort?.field, sort?.sort);
            }}
            initialState={{
                pagination: { paginationModel: { page: currentPage, pageSize } },
                columns: { columnVisibilityModel },
                pinnedColumns: {
                    right: RIGHT_PINNED_COLUMNS,
                    // NOTE: Always include checkbox column as pinned - it doesn't matter if it's actually there or not
                    left: [GRID_CHECKBOX_SELECTION_COL_DEF.field].concat(pinnedColumns),
                },
            }}
            sortModel={sortModel}
            paginationMode="server"
            sortingMode="server"
            onColumnWidthChange={(e) => onColumnWidthChanged(e.colDef.field, e.width)}
            onColumnVisibilityModelChange={onColumnVisibilityModelChange}
            onColumnOrderChange={handleColumnOrderChange}
            columns={columns}
            rows={rows}
            disableRowSelectionOnClick
        />
    );
};

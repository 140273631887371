import { Alert, Skeleton, Stack, Typography } from "@mui/material";
import { useState } from "react";
import { FileUploader } from "react-drag-drop-files";
import type FileUploaderSrc from "react-drag-drop-files/dist/src/FileUploader";
import { FormattedMessage, useIntl } from "react-intl";

// FileUploader is not the best typed, but github issues had a workaround
// https://github.com/KarimMokhtar/react-drag-drop-files/issues/138#issuecomment-2092654245
const FileUploaderTyped: typeof FileUploaderSrc = FileUploader;

const extensions = [
    "webp",
    "pdf",
    "csv",
    "txt",
    "rtf",
    "xls",
    "xlsx",
    "json",
    "ppt",
    "pptx",
    "doc",
    "docx",
    "xml",
    "eml",
    "msg",
    "oft",
];

type Props = {
    disabled?: boolean;
    onChange: (files: FileList) => Promise<void>;
    allowedExtensions?: string[];
};
const MAX_FILESIZE_MB = 30;

export const DocumentDrop = ({ onChange, disabled, allowedExtensions = extensions }: Props) => {
    const { formatMessage } = useIntl();
    const [loading, setLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState<string | null>(null);

    function onUpload(files: FileList) {
        setErrorMessage(null);
        setLoading(true);
        onChange(files).finally(() => setLoading(false));
    }
    if (loading) {
        return (
            <Stack
                sx={{
                    height: 109,
                    width: "100%",
                    border: "1px dashed",
                    borderRadius: 1,
                    p: 1,
                    justifyContent: "center",
                    alignItems: "center",
                }}
                direction="column"
                gap={1}
            >
                <Skeleton variant="rectangular" height={36} width="100%" />
                <Skeleton variant="rectangular" height={24} width="100%" />
            </Stack>
        );
    }
    return (
        <Stack direction="column" gap={1} width="100%">
            <FileUploaderTyped
                multiple
                disabled={disabled}
                types={allowedExtensions}
                label={formatMessage({ defaultMessage: "Drop files here" })}
                maxSize={MAX_FILESIZE_MB}
                onSizeError={() => {
                    setErrorMessage(
                        formatMessage(
                            {
                                defaultMessage: "File size is too large; limit is {limit}MB",
                            },
                            { limit: MAX_FILESIZE_MB }
                        )
                    );
                }}
                onTypeError={() => {
                    setErrorMessage(
                        formatMessage(
                            {
                                defaultMessage: "File type is not supported. Supported types are: {extensions}",
                            },
                            { extensions: allowedExtensions.join(", ") }
                        )
                    );
                }}
                handleChange={(files) => {
                    // the input is wrongly typed as File | File[], but it's actually a FileList - when the multiple prop is set
                    onUpload(files as any as FileList);
                }}
            >
                <Stack
                    direction="row"
                    gap={1}
                    alignItems="center"
                    justifyContent="center"
                    sx={{
                        cursor: "pointer",
                        height: "100%",
                        width: "100%",
                        border: "1px dashed",
                        borderRadius: 1,
                        p: 1,
                    }}
                >
                    <Stack direction="column" gap={1} alignItems="center">
                        <Typography variant="textMd" fontWeight={500}>
                            <FormattedMessage defaultMessage="Drag and drop files here" />
                        </Typography>
                        <Typography variant="caption" color="text.secondary">
                            <FormattedMessage defaultMessage="or click to browse" />
                        </Typography>
                    </Stack>
                </Stack>
            </FileUploaderTyped>
            {errorMessage && (
                <Alert severity="error">
                    <Stack direction="row" gap={1} alignItems="center">
                        <Typography variant="textMd" fontWeight={500}>
                            {errorMessage}
                        </Typography>
                    </Stack>
                </Alert>
            )}
        </Stack>
    );
};

import { Avatar, AvatarGroup, Skeleton, Stack, Tooltip, Typography } from "@mui/material";
import { FormattedMessage } from "react-intl";
import { useUsers } from "@/entities/users";
import { UserItem } from "./UserItem";
import { UserSurplus } from "./UserSurplus";

const MAX_AVATARS = 3;
export interface UserCellProps {
    userIds: string[];
}

export const UserCell: React.FC<UserCellProps> = ({ userIds }) => {
    const { loading, users } = useUsers(userIds);

    if (loading) return <Skeleton height="40px" width="300px" animation="wave" />;

    if (users.length === 0) {
        return (
            <Typography variant="textSm" color="textTextHelper">
                <FormattedMessage defaultMessage="Not assigned" />
            </Typography>
        );
    }

    if (users.length === 1) {
        return (
            <Stack justifyContent="center" height="100%">
                <UserItem user={users[0]} />
            </Stack>
        );
    }

    return (
        <Stack justifyContent="center" alignItems="flex-start" height="100%">
            <AvatarGroup
                max={MAX_AVATARS}
                spacing={1}
                renderSurplus={() => <UserSurplus textVariant="textXs" users={users.slice(MAX_AVATARS - 1)} />}
                slotProps={{ surplus: { sx: { width: 24, height: 24 } } }}
            >
                {users.map((user) => (
                    <Tooltip key={user.id} title={<UserItem user={user} />}>
                        <Avatar src="" sx={{ width: 24, height: 24 }}>
                            <Typography variant="textXs">{`${user.firstName[0]}${user.lastName[0]}`}</Typography>
                        </Avatar>
                    </Tooltip>
                ))}
            </AvatarGroup>
        </Stack>
    );
};

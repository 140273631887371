import { Truck } from "@ignite-analytics/icons";
import { Chip, Stack, Tooltip } from "@mui/material";
import { EllipticTypography } from "../EllipticTypography";

interface TopSupplierChipProps {
    name: string;
    id: string;
    size?: "small" | "medium" | "large";
    disabled?: boolean;
}

function supplierLink(supplierId: string) {
    return `/supplier-profile/${supplierId}/overview/`;
}

export const SupplierChip = ({ name, id, disabled, size = "small" }: TopSupplierChipProps) => {
    const onClick = (e: React.MouseEvent) => {
        // We don't want this to propagate to the row click
        e.stopPropagation();
    };
    return (
        <Chip
            href={supplierLink(id)}
            component="a"
            disabled={disabled}
            size={size}
            sx={{
                textOverflow: "ellipsis",
                maxWidth: "354px",
            }}
            icon={<Truck />}
            color="suppliers"
            label={<EllipticTypography>{name}</EllipticTypography>}
            onClick={onClick}
            clickable
            target="_blank"
            rel="noopener noreferrer"
        />
    );
}

interface Props {
    suppliers?: { name: string; id: string }[] | null;
}
export const SupplierChips = ({ suppliers }: Props) => {
    if (!suppliers || suppliers.length === 0) {
        return null;
    }

    const onClick = (e: React.MouseEvent) => {
        // We don't want this to propagate to the row click
        e.stopPropagation();
    };

    const [biggestSupplier, ...rest] = suppliers;

    return (
        <Stack direction="row" spacing={1} maxWidth="100%">
            <Chip
                href={supplierLink(biggestSupplier.id)}
                component="a"
                icon={<Truck />}
                size="medium"
                color="suppliers"
                label={<EllipticTypography>{biggestSupplier.name}</EllipticTypography>}
                onClick={onClick}
                sx={{
                    overflow: "hidden",
                    textOverflow: "hidden",
                    maxWidth: "354px",
                    ":&hover": {
                        cursor: "pointer",
                    },
                }}
                clickable
                target="_blank"
                rel="noopener noreferrer"
            />
            {rest.length > 0 && (
                <Tooltip
                    title={
                        <Stack spacing={1} p={1} sx={{ ":&hover": { cursor: "pointer" } }}>
                            {rest.map((supplier) => (
                                <Chip
                                    href={supplierLink(supplier.id)}
                                    clickable
                                    component="a"
                                    key={supplier.id}
                                    icon={<Truck />}
                                    size="medium"
                                    color="suppliers"
                                    label={<EllipticTypography>{supplier.name}</EllipticTypography>}
                                    onClick={onClick}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                />
                            ))}
                        </Stack>
                    }
                >
                    <Chip size="medium" color="suppliers" label={`+${rest.length}`} />
                </Tooltip>
            )}
        </Stack>
    );
}

import { DataGridProProps, gridClasses } from "@mui/x-data-grid-pro";

const defaultSx: DataGridProProps["sx"] = {
    [`& .${gridClasses.toolbarContainer}`]: {
        padding: (t) => t.spacing(2.5),
        display: "flex",
        flexDirection: "row",
        spacing: (t) => t.spacing(1.5),
        justifyContent: "flex-end",
        alignItems: "center",
        borderBottom: "1px solid var(--DataGrid-rowBorderColor)",
    },
    /**
     * @todo This should be fixed in the theme package. Sets the background color of the pinned columns to the same as the container background.
     * Intentionally adding here to test get feedback from the design team on the interactions before
     * addressing it in the theme package.
     */
    [`& .${gridClasses.columnHeader}`]: {
        "--DataGrid-pinnedBackground": "var(--DataGrid-containerBackground)",
    },
    [`& .${gridClasses.editInputCell}`]: {
        borderRadius: "4px",
    },
    [`& .${gridClasses.cell}:focus-within`]: {
        outlineColor: (t) => t.palette.secondary.main,
        borderRadius: "4px",
    },
    [`& .${gridClasses.cell}.${gridClasses["cell--editing"]}:focus-within`]: {
        outlineColor: (t) => t.palette.secondary.main,
    },
    [`& .${gridClasses.cell}.${gridClasses["cell--editable"]}:hover`]: {
        outline: (t) => `1px solid ${t.palette.secondary.main}`,
        outlineOffset: "-1px",
        borderRadius: "4px",
    },
};
//
// stolen from suppliers app
const customGridClasses = {
    dragIndicator: "CustomMuiDataGrid-dragIndicator",
};
/**
 * These are purposely defined outside the component to avoid them being recreated on every render.
 */
export const customDataGridStyling: DataGridProProps["sx"] = {
    ...defaultSx,
    "--unstable_DataGrid-radius": "0px",
    /**
     * For column headers that have `draggable="true"` attribute we display the drag indicator icon
     */
    [`& .${gridClasses.columnHeaderDraggableContainer}[draggable="true"] .${customGridClasses.dragIndicator}`]: {
        display: "inline-block",
    },
    "& .MuiDataGrid-pinnedColumnHeaders": { background: (theme) => theme.palette.gray.main },
    "& .MuiDataGrid-columnHeaders": { background: (theme) => theme.palette.gray.main },
    // Add a border radius of 1
    borderRadius: 1,
    [`& .${gridClasses.cell}:focus, & .${gridClasses.cell}:focus-within`]: {
        outline: "none",
    },
    [`& .${gridClasses.cell}`]: {
        borderRight: "none",
    },
    [`& .${gridClasses.columnHeader}`]: {
        borderRight: "none",
    },
};

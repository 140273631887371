import { defineMessages } from "react-intl";
import { fm } from "src/contexts/IntlContext";
import { ContractTableMessages } from "./types";

const messages = defineMessages({
    toolbarDensity: {
        id: "contractstable.toolbarDensity",
        description: "Contracts table: Density settings in toolbar",
        defaultMessage: "Density",
    },
    toolbarColumns: {
        id: "contractstable.toolbarColumns",
        description: "Contracts table: Column settings in toolbar",
        defaultMessage: "Columns",
    },
    toolbarExport: {
        id: "contractstable.toolbarExport",
        description: "Contracts table: Export in toolbar",
        defaultMessage: "Export",
    },
    toolbarExportCSV: {
        id: "contractstable.toolbarExportCSV",
        description: "Contracts table: Export to CSV from toolbar",
        defaultMessage: "Export to CSV",
    },
    toolbarExportPrint: {
        id: "contractstable.toolbarExportPrint",
        description: "Contracts table: Print from toolbar",
        defaultMessage: "Print",
    },
    toolbarDensityComfortable: {
        id: "contractstable.toolbarDensityComfortable",
        description: "Contracts table: Comfortable density from toolbar",
        defaultMessage: "Comfortable",
    },
    toolbarDensityStandard: {
        id: "contractstable.toolbarDensityStandard",
        description: "Contracts table: Standard density from toolbar",
        defaultMessage: "Standard",
    },
    toolbarDensityCompact: {
        id: "contractstable.toolbarDensityCompact",
        description: "Contracts table: Compact density from toolbar",
        defaultMessage: "Compact",
    },
    columnsPanelHideAllButton: {
        id: "contractstable.columnsPanelHideAllButton",
        description: "Contracts table: Hide all columns from panel",
        defaultMessage: "Hide All",
    },
    columnsPanelShowAllButton: {
        id: "contractstable.columnsPanelShowAllButton",
        description: "Contracts table: Show all columns from panel",
        defaultMessage: "Show All",
    },
    columnsPanelTextFieldLabel: {
        id: "contractstable.columnsPanelTextFieldLabel",
        description: "Contracts table: Find column input label from panel",
        defaultMessage: "Find column",
    },
    columnsPanelTextFieldPlaceholder: {
        id: "contractstable.columnsPanelTextFieldPlaceholder",
        description: "Contracts table: Find column placeholder from panel",
        defaultMessage: "",
    },
    columnMenuShowColumns: {
        id: "contractstable.columnMenuShowColumns",
        description: "Contracts table: Find column placeholder from panel",
        defaultMessage: "Show columns",
    },
    columnMenuLabel: {
        id: "contractstable.columnMenuLabel",
        description: "Contracts table: column settings label",
        defaultMessage: "Menu",
    },
    columnHeaderSortIconLabel: {
        id: "contractstable.columnHeaderSortIconLabel",
        description: "Contracts table: column sort icon label",
        defaultMessage: "Sort",
    },
    columnMenuHideColumn: {
        id: "contractstable.columnMenuHideColumn",
        description: "Contracts table: Hide column from menu",
        defaultMessage: "Hide column",
    },
    columnMenuSortAsc: {
        id: "contractstable.columnMenuSortAsc",
        description: "Contracts table: Sort ASC from menu",
        defaultMessage: "Sort by ASC",
    },
    columnMenuSortDesc: {
        id: "contractstable.columnMenuSortDesc",
        description: "Contracts table: Sort DESC from menu",
        defaultMessage: "Sort by DESC",
    },
    columnMenuUnsort: {
        id: "contractstable.columnMenuUnsort",
        description: "Contracts table: Unsort from menu",
        defaultMessage: "Unsort",
    },
    columnMenuEdit: {
        id: "contractstable.columnEdit",
        description: "Contracts table: edit column",
        defaultMessage: "Edit",
    },
    columnMenuRemove: {
        id: "contractstable.columnRemove",
        description: "Contracts table: remove column",
        defaultMessage: "Remove",
    },
    pinToLeft: {
        id: "contractstable.pinToLeft",
        description: "Contracts table: Pin to left from menu",
        defaultMessage: "Pin to left",
    },
    pinToRight: {
        id: "contractstable.pinToRight",
        description: "Contracts table: Pin to right from menu",
        defaultMessage: "Pin to right",
    },
    PaginationRowsPerPage: {
        id: "contractstable.labelRowsPerPage",
        description: "Contracts table: Rows per page footer label",
        defaultMessage: "Rows per page:",
    },
    PaginationOf: {
        id: "contractstable.labelPaginationOf",
        description: 'Contracts table: "of" from pagination: 1-5 of 10',
        defaultMessage: "of",
    },
    FooterSelectedInfo: {
        id: "contractstable.footerSelectedInfo",
        description: 'Contracts table: Info on how many rows were selected ex: "4 Selected"',
        defaultMessage: "Selected",
    },
    privateLabel: {
        id: "contractstable.privateLabel",
        description: 'Restricted contract label"',
        defaultMessage: "Restricted",
    },
    publicLabel: {
        id: "contractstable.publicLabel",
        description: 'Public contract label"',
        defaultMessage: "Public",
    },
});

export const makeMessages = (): ContractTableMessages => ({
    columnMessages: {
        privateLabel: fm(messages.privateLabel).toString(),
        publicLabel: fm(messages.publicLabel).toString(),
    },
    columnMenuMessages: {
        removeColumn: fm(messages.columnMenuRemove).toString(),
        editColumn: fm(messages.columnMenuEdit).toString(),
    },
    muiMessages: {
        MuiTablePagination: {
            labelRowsPerPage: fm(messages.PaginationRowsPerPage).toString(),
            labelDisplayedRows: ({ from, to, count }) =>
                `${from} - ${to} ${fm(messages.PaginationOf).toString()} ${count}`,
        },
        footerRowSelected: (count: number) => `${count} ${fm(messages.FooterSelectedInfo).toString()}`,
        toolbarDensity: fm(messages.toolbarDensity).toString(),
        toolbarColumns: fm(messages.toolbarColumns).toString(),
        toolbarExport: fm(messages.toolbarExport).toString(),
        toolbarExportCSV: fm(messages.toolbarExportCSV).toString(),
        toolbarExportPrint: fm(messages.toolbarExportPrint).toString(),
        toolbarDensityComfortable: fm(messages.toolbarDensityComfortable).toString(),
        toolbarDensityStandard: fm(messages.toolbarDensityStandard).toString(),
        toolbarDensityCompact: fm(messages.toolbarDensityCompact).toString(),
        columnMenuLabel: fm(messages.columnMenuLabel).toString(),
        columnMenuShowColumns: fm(messages.columnMenuShowColumns).toString(),
        columnHeaderSortIconLabel: fm(messages.columnHeaderSortIconLabel).toString(),
        columnMenuHideColumn: fm(messages.columnMenuHideColumn).toString(),
        columnMenuSortAsc: fm(messages.columnMenuSortAsc).toString(),
        columnMenuSortDesc: fm(messages.columnMenuSortDesc).toString(),
        columnMenuUnsort: fm(messages.columnMenuUnsort).toString(),
        pinToLeft: fm(messages.pinToLeft).toString(),
        pinToRight: fm(messages.pinToRight).toString(),
    },
});

import { ShapeIcon } from "@ignite-analytics/components";
import { Trash, X } from "@ignite-analytics/icons";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    IconButton,
    Stack,
    Typography,
} from "@mui/material";
import React from "react";
import { FormattedMessage } from "react-intl";

type Props = {
    open: boolean;
    onConfirm: () => void;
    onCancel: () => void;
    numContracts: number;
};

export const ConfirmBulkDeleteContractsDialog: React.FC<Props> = ({ numContracts, open, onCancel, onConfirm }) => {
    return (
        <Dialog open={open} onClose={onCancel} fullWidth maxWidth="xs">
            <DialogTitle>
                <Stack direction="row" justifyContent="end">
                    <IconButton onClick={onCancel}>
                        <X />
                    </IconButton>
                </Stack>
                <Stack direction="row" justifyContent="center">
                    <ShapeIcon size="medium" color="error">
                        <Trash />
                    </ShapeIcon>
                </Stack>
            </DialogTitle>
            <DialogContent>
                <Stack pt={3} spacing={1} alignItems="center" textAlign="center">
                    <Typography variant="textLg" fontWeight={500}>
                        <FormattedMessage
                            defaultMessage="Delete the {numContracts} selected contract(s)"
                            values={{ numContracts }}
                        />
                    </Typography>
                    <Typography variant="textSm" color="textTextBody">
                        <FormattedMessage
                            defaultMessage="Are you sure want to delete {numContracts} contract(s)? This action is irreversible."
                            values={{ numContracts }}
                        />
                    </Typography>
                </Stack>
            </DialogContent>
            <DialogActions>
                <Button onClick={onCancel} fullWidth color="secondary" sx={{ mr: 1 }}>
                    <FormattedMessage defaultMessage="Cancel" />
                </Button>
                <Button onClick={onConfirm} fullWidth color="error">
                    <FormattedMessage defaultMessage="Delete" />
                </Button>
            </DialogActions>
        </Dialog>
    );
};

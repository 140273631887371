import { useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useNavigate } from "@tanstack/react-router";
import {
    Button,
    Chip,
    IconButton,
    ListItem,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    Menu,
    MenuItem,
    Stack,
    TextField,
} from "@mui/material";
import { DotsHorizontal, FileBookmarkSolid, PenSolid, TrashSolid } from "@ignite-analytics/icons";
import { layoutDetailsRoute } from "@/Router";
import { LayoutListItem } from "@/models/layout";

type LayoutItemProps = {
    layoutListItem: LayoutListItem;
    label: string;
    isDefault: boolean;
    updateLayout: (layout: LayoutListItem) => Promise<void>;
    deleteLayout: (layoutId: string) => Promise<void>;
    setAsDefault: (layoutId: string) => Promise<void>;
};

export const LayoutItem = ({
    layoutListItem,
    label,
    isDefault,
    updateLayout,
    deleteLayout,
    setAsDefault,
}: LayoutItemProps) => {
    const [isEditing, setIsEditing] = useState<boolean>(false);
    const [name, setName] = useState<string>(layoutListItem.name);
    const navigate = useNavigate();
    const { formatMessage } = useIntl();
    const navigateToLayoutDetails = () => {
        navigate({ to: layoutDetailsRoute.fullPath, params: { id: layoutListItem.id } });
    };
    const abort = () => {
        setIsEditing(false);
    };
    const accept = async (inputValue: string) => {
        const newName: LayoutListItem = {
            id: layoutListItem.id,
            name: inputValue,
        };
        await updateLayout(newName);
        setIsEditing(false);
    };
    const deleteExistingLayout = async (id: string) => {
        await deleteLayout(id);
    };

    const setLayoutAsDefault = async (id: string) => {
        await setAsDefault(id);
    };

    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);

    if (isEditing) {
        return (
            <ListItem key={layoutListItem.id}>
                <Stack
                    direction="row"
                    gap={3}
                    component="form"
                    alignItems="center"
                    justifyContent="space-between"
                    width="100%"
                    onSubmit={(e) => {
                        e.preventDefault();
                        if (name) {
                            accept(name);
                        }
                    }}
                >
                    <TextField
                        autoFocus
                        size="small"
                        placeholder={label}
                        defaultValue={layoutListItem.name}
                        onChange={({ target }) => setName(target.value)}
                        fullWidth
                    />
                    <Stack direction="row" gap={2} alignItems="center">
                        <Button onClick={abort} size="small" color="secondary">
                            <FormattedMessage defaultMessage="Cancel" />
                        </Button>
                        <Button type="submit" size="small">
                            <FormattedMessage defaultMessage="Save" />
                        </Button>
                    </Stack>
                </Stack>
            </ListItem>
        );
    }

    return (
        <ListItem key={layoutListItem.id}>
            <ListItemButton>
                <ListItemText onClick={navigateToLayoutDetails}>
                    {layoutListItem.name}
                    {isDefault && (
                        <Chip label={formatMessage({ defaultMessage: "default" })} size="small" sx={{ ml: 1 }} />
                    )}
                </ListItemText>
                <>
                    <IconButton
                        sx={{ padding: 0, position: "absolute", right: 0 }}
                        size="small"
                        aria-label="menu"
                        aria-haspopup="true"
                        onClick={(e) => {
                            setAnchorEl(e.currentTarget);
                        }}
                    >
                        <DotsHorizontal />
                    </IconButton>
                    <Menu anchorEl={anchorEl} open={open} onClose={() => setAnchorEl(null)}>
                        <MenuItem
                            onClick={() => {
                                setLayoutAsDefault(layoutListItem.id);
                                setAnchorEl(null);
                            }}
                        >
                            <ListItemIcon>
                                <FileBookmarkSolid />
                            </ListItemIcon>
                            <ListItemText>
                                <FormattedMessage defaultMessage="Set as default" />
                            </ListItemText>
                        </MenuItem>
                        <MenuItem
                            onClick={() => {
                                setIsEditing(true);
                                setAnchorEl(null);
                            }}
                        >
                            <ListItemIcon>
                                <PenSolid />
                            </ListItemIcon>
                            <ListItemText>
                                <FormattedMessage defaultMessage="Rename" />
                            </ListItemText>
                        </MenuItem>
                        <MenuItem
                            disabled={isDefault}
                            onClick={() => {
                                deleteExistingLayout(layoutListItem.id);
                                setAnchorEl(null);
                            }}
                        >
                            <ListItemIcon>
                                <TrashSolid />
                            </ListItemIcon>
                            <ListItemText>
                                <FormattedMessage defaultMessage="Delete" />
                            </ListItemText>
                        </MenuItem>
                    </Menu>
                </>
            </ListItemButton>
        </ListItem>
    );
};

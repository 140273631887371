import { List, ListItem, Tooltip, Typography, TypographyProps } from "@mui/material";

import { useState } from "react";
import { User } from "@/entities/users";
import { UserItem } from "./UserItem";

interface Props {
    users: Pick<User, "id" | "firstName" | "lastName">[];
    textVariant?: TypographyProps["variant"];
}

export const UserSurplus: React.FC<Props> = ({ users, textVariant = "textSm" }) => {
    const [isOpen, setIsOpen] = useState(false);

    const handleTooltipOpen = () => {
        setIsOpen(true);
    };

    const handleTooltipClose = () => {
        setIsOpen(false);
    };

    return (
        <Tooltip
            title={
                <List sx={{ maxHeight: 300, overflow: "auto" }}>
                    {users.map((user) => (
                        <ListItem key={user.id}>
                            <UserItem user={user} />
                        </ListItem>
                    ))}
                </List>
            }
            placement="bottom-start"
            open={isOpen}
            onOpen={handleTooltipOpen}
            onClose={handleTooltipClose}
        >
            <Typography variant={textVariant}>+{users.length}</Typography>
        </Tooltip>
    );
};

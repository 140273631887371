import { Contract, PatchContractCommand } from "@/types";

export function computePatchContractData(dirty: Contract, reference: Contract) {
    const res: PatchContractCommand[] = [];
    if (dirty.title !== reference.title) {
        res.push({ operation: "replace", path: "title", data: dirty.title });
    }
    if (dirty.description !== reference.description) {
        res.push({ operation: "replace", path: "description", data: dirty.description });
    }
    if (dirty.startDate !== reference.startDate) {
        res.push({ operation: "replace", path: "startDate", data: dirty.startDate });
    }
    if (dirty.endDate !== reference.endDate) {
        res.push({ operation: "replace", path: "endDate", data: dirty.endDate });
    }
    if (dirty.renewalDate !== reference.renewalDate) {
        res.push({ operation: "replace", path: "renewalDate", data: dirty.renewalDate });
    }
    if (dirty.supplierId !== reference.supplierId || dirty.contactPersonIds !== reference.contactPersonIds) {
        res.push({
            operation: "replace",
            path: "supplier",
            data: { supplierId: dirty.supplierId, contactPersonIds: dirty.contactPersonIds },
        });
    }
    if (dirty.contractResponsibleIds !== reference.contractResponsibleIds) {
        res.push({ operation: "replace", path: "contractResponsibleIds", data: dirty.contractResponsibleIds });
    }
    if (dirty.customFields !== reference.customFields) {
        res.push({ operation: "replace", path: "customFields", data: dirty.customFields });
    }
    return res;
}

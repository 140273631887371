import { Contract } from "@/types";
import { Group } from "@/types/Layout";

export function customfieldRenderOrder(contract: Contract, group: Group) {
    return group.items
        .filter((item) => item.visible)
        .sort((a, b) => a.order - b.order)
        .map((f) => contract.customFields.find((cf) => cf.id === f.refId))
        .filter((x) => x != null);
}

import { useFeatureToggle } from "@ignite-analytics/feature-toggle";
import { FileArrowUp, Plus } from "@ignite-analytics/icons";
import { Button } from "@mui/material";
import { FormattedMessage, useIntl } from "react-intl";
import { usePermissionCheck } from "@/hooks/usePermissionCheck";
import { Entry, RiddleMenuWithButton } from "@/components/ui/Menu";

type Props = {
    onOpenCreateContractModal: () => void;
    onOpenUploadContractsModal: () => void;
};

export const AddContractButton: React.FC<Props> = ({ onOpenCreateContractModal, onOpenUploadContractsModal }) => {
    const { formatMessage } = useIntl();

    const writeAccess = usePermissionCheck("contracts", "general", "write").data ?? false;

    const showUploadContracts = useFeatureToggle("bulk-upload-contracts");

    const entries: Entry[] = [
        {
            label: formatMessage({ defaultMessage: "Add contract" }),
            icon: { pos: "start", component: <Plus /> },
            onClick: onOpenCreateContractModal,
        },
        {
            label: formatMessage({ defaultMessage: "Upload contracts" }),
            icon: { pos: "start", component: <FileArrowUp /> },
            onClick: onOpenUploadContractsModal,
        },
    ];

    if (!showUploadContracts) {
        return (
            <Button
                variant="contained"
                color="primary"
                size="small"
                onClick={onOpenCreateContractModal}
                startIcon={<Plus />}
            >
                <FormattedMessage defaultMessage="Add contract" />
            </Button>
        );
    }

    return (
        <RiddleMenuWithButton
            disabled={!writeAccess}
            buttonLabel={formatMessage({ defaultMessage: "Add contract(s)" })}
            color="primary"
            entries={entries}
        />
    );
};

import { Plus, Trash } from "@ignite-analytics/icons";
import { track } from "@ignite-analytics/track";
import {
    Button,
    FormControlLabel,
    Grid2 as Grid,
    IconButton,
    Radio,
    RadioGroup,
    Stack,
    Typography,
} from "@mui/material";
import FormControl from "@mui/material/FormControl";
import { useCallback, useEffect, useMemo, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import type { Contact } from "src/entities/users";
import { ContactsDropdown } from "../../ui/ContactDropdown/contactsEdit";
import { ContactsList } from "../../ui/ContactList/contactList";
import { Popup } from "../../ui/Popup/popup";
import { ContractPermissions } from "../manageAccessPopup";

export type EntitledUser = {
    subjectId: string;
    permission: string;
};

export type Access = "restricted" | "public" | undefined;

type Props = {
    isOpen: boolean;
    defaultAccess: Access;
    contacts: Contact[];
    contractsPermission: ContractPermissions[];
    onClose: () => void;
    onSave: (permissions: ContractPermissions[], access: Access) => void;
    contractsIds: string[];
};

const getContractPermissionMap = (contractId: string, permissions: ContractPermissions[]): Record<string, string> => {
    const contractPermissions = permissions.find((p) => p.objectId === contractId);
    if (!contractPermissions) {
        return {};
    }

    return Object.fromEntries(contractPermissions.entitleds.map((e) => [e.subjectId, e.permission]) ?? []);
};

const parseRespose = (
    response: ContractPermissions[],
    contractsIds: string[]
): Record<string, Record<string, string>> => {
    return Object.fromEntries(contractsIds.map((p: string) => [p, getContractPermissionMap(p, response)]));
};

type PermissionType = { type: "read" } | { type: "mixed"; numPermissions: number };
export const ManageAccessPopup = ({
    isOpen,
    contacts,
    defaultAccess,
    contractsPermission,
    onClose,
    onSave,
    contractsIds,
}: Props) => {
    const multipleContracts = contractsIds.length > 1;
    const [entitledContactsList, setEntitledContactsList] = useState<Contact[]>([]);
    const [selectedInDropdown, setSelectedInDropdown] = useState<Contact[]>([]);
    const [subjectIdToPermissions, setSubjectIdToPermissions] = useState<Record<string, PermissionType>>({});
    const defaultPermission = "read";
    const [access, setAccess] = useState<Access>(defaultAccess);
    const [contractsToPermissions, setContractsToPermissions] = useState<Record<string, Record<string, string>>>({});
    const { formatMessage } = useIntl();

    const availableContacts = useMemo(
        () => contacts.filter((contact) => !entitledContactsList.includes(contact)),
        [contacts, entitledContactsList]
    );

    useEffect(() => {
        const contactsList: Contact[] = [];
        const entitledUsersIds = contractsPermission.flatMap((entitled) =>
            entitled.entitleds.map((user) => user.subjectId)
        );

        contacts.forEach((contact) => {
            if (entitledUsersIds.includes(contact.id)) contactsList.push(contact);
        });
        setEntitledContactsList(contactsList);
        const subjectIdToOccurance = contractsPermission
            .flatMap((perm) => perm.entitleds)
            .reduce<{ [subjectId: string]: number }>((acc, current) => {
                if (current.subjectId in acc) {
                    acc[current.subjectId] += 1;
                } else {
                    acc[current.subjectId] = 1;
                }
                return acc;
            }, {});

        const subjectIdToPermission = Object.fromEntries<PermissionType>(
            Object.entries(subjectIdToOccurance).map(([subId, occurances]) => {
                if (occurances === contractsIds.length) {
                    return [subId, { type: "read" }];
                }
                return [subId, { type: "mixed", numPermissions: occurances }];
            })
        );
        setContractsToPermissions(parseRespose(contractsPermission, contractsIds));

        setSubjectIdToPermissions(subjectIdToPermission);
    }, [contacts, contractsPermission, contractsIds.length, contractsIds]);

    const addPermission = (contactsToAdd: Contact[]) => {
        const newContacts = contactsToAdd.filter((contact) => !entitledContactsList.includes(contact));
        setEntitledContactsList([...entitledContactsList, ...newContacts]);
        Object.keys(contractsToPermissions).forEach((contractId) => {
            contactsToAdd.forEach((contact) => {
                contractsToPermissions[contractId][contact.id] = defaultPermission;
            });
        });

        track("ManageAccessPopup user add", { users: contactsToAdd.length });

        setContractsToPermissions(contractsToPermissions);
        setSelectedInDropdown([]);
    };

    const removePermission = useCallback(
        (contactIdToRemove: string) => {
            Object.keys(contractsToPermissions).forEach((contractId) => {
                delete contractsToPermissions[contractId][contactIdToRemove];
            });
            setContractsToPermissions(contractsToPermissions);

            const filteredContacts = entitledContactsList.filter((contact) => contact.id !== contactIdToRemove);
            setSubjectIdToPermissions((prev) => {
                const { [contactIdToRemove]: _, ...rest } = prev;
                return rest;
            });
            setEntitledContactsList(filteredContacts);
        },
        [contractsToPermissions, entitledContactsList]
    );

    const handleCancel = () => {
        onClose();
    };

    const handleOnSave = () => {
        const newPermissions: ContractPermissions[] = [];

        Object.entries(contractsToPermissions).forEach(([contractId, permissionsMap]) => {
            const entitleds: EntitledUser[] = [];
            Object.entries(permissionsMap).forEach(([subjectId, permission]) => {
                entitleds.push({ subjectId, permission });
            });
            newPermissions.push({ objectId: contractId, entitleds });
        });
        onSave(newPermissions, access);
        onClose();
    };

    const handleAccessChange = (event: React.ChangeEvent<HTMLInputElement>) =>
        setAccess((event.target as HTMLInputElement).value as Access);

    const renderIndividualPermissionActions = useCallback(
        (contactId: string) => {
            const permissions = subjectIdToPermissions[contactId];
            const totalContracts = contractsIds.length;
            return (
                <Stack direction="row" alignItems="center" justifyContent="space-between" gap={5}>
                    {multipleContracts && (
                        <Typography variant="textSm">
                            {permissions?.type !== "mixed" ? (
                                <FormattedMessage
                                    defaultMessage="All {totalContracts} contracts"
                                    values={{ totalContracts }}
                                />
                            ) : (
                                <FormattedMessage
                                    defaultMessage="{numPermissions} of {totalContracts} contracts"
                                    values={{ numPermissions: permissions.numPermissions, totalContracts }}
                                />
                            )}
                        </Typography>
                    )}
                    <IconButton onClick={() => removePermission(contactId)}>
                        <Trash />
                    </IconButton>
                </Stack>
            );
        },
        [contractsIds.length, multipleContracts, removePermission, subjectIdToPermissions]
    );

    const restrictedLabel = (
        <Stack direction="column">
            <Typography variant="subtitle1">
                <FormattedMessage defaultMessage="Restricted" />
            </Typography>
            <Typography variant="body2">
                <FormattedMessage defaultMessage="Restricted contracts can also be viewed by users with admin role or contract responsibles." />
            </Typography>
        </Stack>
    );
    const publicLabel = (
        <Stack direction="column">
            <Typography variant="subtitle1">
                <FormattedMessage defaultMessage="Public" />
            </Typography>
            <Typography variant="body2">
                <FormattedMessage defaultMessage="Contracts marked as public are visible to all users with view access." />
            </Typography>
        </Stack>
    );

    return (
        <Popup
            cancelLabel={formatMessage({ defaultMessage: "Cancel" })}
            isOpen={isOpen}
            onCancel={handleCancel}
            onSubmit={async () => handleOnSave()}
            titleLabel={
                multipleContracts
                    ? formatMessage(
                          { defaultMessage: "Manage access for {numContracts} selected contracts" },
                          { numContracts: contractsIds.length }
                      )
                    : formatMessage({ defaultMessage: "Manage access for contract" })
            }
            submitLabel={formatMessage({ defaultMessage: "Save" })}
            disableSubmitButton={access === undefined}
        >
            <FormControl>
                <RadioGroup name="access-radio-group" value={access} onChange={handleAccessChange}>
                    <FormControlLabel sx={{ m: 1 }} value="public" control={<Radio />} label={publicLabel} />
                    <FormControlLabel sx={{ m: 1 }} value="restricted" control={<Radio />} label={restrictedLabel} />
                </RadioGroup>
            </FormControl>
            {access === "restricted" && (
                <>
                    <Stack
                        direction="row"
                        alignItems="center"
                        justifyContent="space-between"
                        spacing={1}
                        paddingTop={4}
                    >
                        <ContactsDropdown
                            key={`manageAccessPopup-${selectedInDropdown.length}`}
                            placeholder={formatMessage({ defaultMessage: "Add one or more people" })}
                            multiple
                            defaultValue={selectedInDropdown}
                            options={availableContacts}
                            onChange={setSelectedInDropdown}
                        />
                        <Button
                            startIcon={<Plus />}
                            type="button"
                            variant="text"
                            disabled={selectedInDropdown.length === 0}
                            onClick={() => addPermission(selectedInDropdown)}
                        >
                            <FormattedMessage defaultMessage="Add" />
                        </Button>
                    </Stack>
                    <Stack overflow="auto" pt={2}>
                        <Grid container direction="row">
                            <Grid size={7}>
                                <Typography variant="textSm" fontWeight={500}>
                                    <FormattedMessage defaultMessage="User(s)" />
                                </Typography>
                            </Grid>
                            <Grid size={5}>
                                <Stack direction="row" gap={8.5} justifyContent="end">
                                    {multipleContracts && (
                                        <Typography variant="textSm" fontWeight={500}>
                                            <FormattedMessage defaultMessage="View access" />
                                        </Typography>
                                    )}
                                    <Typography variant="textSm" fontWeight={500}>
                                        <FormattedMessage defaultMessage="Remove" />
                                    </Typography>
                                </Stack>
                            </Grid>
                        </Grid>
                        <ContactsList
                            contacts={entitledContactsList}
                            secondaryAction={(contactWithPermission: Contact) =>
                                renderIndividualPermissionActions(contactWithPermission.id)
                            }
                        />
                    </Stack>
                </>
            )}
        </Popup>
    );
};

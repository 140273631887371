// The header should be sticky when the user scrolls. We achieve that by inserting a zero-sized component

import { useEffect, useRef, useState } from "react";

export function useDisplayHeaderShadow() {
    // at the top and observing whether we have scrolled or not.
    const contentRef = useRef<HTMLDivElement>(null);
    const [displayHeaderShadow, setDisplayHeaderShadow] = useState(false);
    useEffect(() => {
        if (contentRef.current) {
            const obs = new IntersectionObserver(
                (entries) => {
                    entries.forEach((entry) => {
                        if (entry.isIntersecting) {
                            setDisplayHeaderShadow(false);
                        } else {
                            setDisplayHeaderShadow(true);
                        }
                    });
                },
                { threshold: 1 }
            );
            obs.observe(contentRef.current);
            return () => obs.disconnect();
        }
    }, [contentRef]);
    return { contentRef, displayHeaderShadow };
}

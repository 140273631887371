import { useCallback, useMemo, useState } from "react";
import { SnackbarContext } from "./context";
import { Snackbar } from "@/components/Snackbar";
import { SnackbarProps } from "./types";
import { TopSnackbar } from "@/components/TopSnackbar";

export const SnackbarProvider: React.FC<React.PropsWithChildren<unknown>> = ({ children }) => {
    const [snack, setSnack] = useState<SnackbarProps | null>(null);

    const handleClose = useCallback(() => {
        setSnack(null);
    }, []);

    const postSnackbar = useCallback((props: SnackbarProps) => {
        setSnack(props);
    }, []);

    const value = useMemo(() => ({ postSnackbar }), [postSnackbar]);

    return (
        <SnackbarContext.Provider value={value}>
            {snack?.topBar ? (
                <TopSnackbar message={snack?.message} onClose={handleClose} />
            ) : (
                <Snackbar open={!!snack} message={snack?.message} href={snack?.href} onClose={handleClose} />
            )}
            {children}
        </SnackbarContext.Provider>
    );
};

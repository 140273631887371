import { X } from "@ignite-analytics/icons";
import { LoadingButton } from "@mui/lab";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    IconButton,
    Typography,
    Stack,
} from "@mui/material";
import React, { useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { makeContractsApiHttp as http } from "src/http";
import { contractListQueryKeyPredicate } from "@/querykeys";
import { useErrorHandler } from "@/errorHandling/ErrorHandlerContext";
import { usePermissionHandler } from "@/contexts/PermissionsContext";
import { queryClient } from "@/contexts";
import { DocumentDrop } from "../FileAttachments/DocumentDrop";
import { useSnackbar } from "@/contexts/useSnackbar";

type Props = {
    open: boolean;
    onClose: () => void;
};

export const UploadContractsModal: React.FC<Props> = ({ open, onClose }: Props) => {
    const [file, setFile] = useState<File | null>(null);
    const [loading, setLoading] = useState(false);

    const { formatNumber } = useIntl();
    const { checkPermissions } = usePermissionHandler();
    const { handleError } = useErrorHandler();
    const { postSnackbar } = useSnackbar();
    const hasGeneralWritePermission = checkPermissions("general", "write");

    const handleSubmit = () => {
        if (!file) return;
        setLoading(true);

        const formData = new FormData();
        formData.append("file", file);
        http()
            .post("/contracts/upload-contracts-bulk", formData, {
                headers: {
                    "Content-Type": "multipart/form-data",
                },
            })
            .then(() => {
                postSnackbar({
                    message: (
                        <FormattedMessage
                            defaultMessage="Contracts uploaded successfully. See the notification for more information."
                            description="Notification message displayed when contracts are uploaded successfully."
                        />
                    ),
                });
                onClose();
            })
            .catch(handleError)
            .finally(() => {
                setLoading(false);
            });

        queryClient.removeQueries({ predicate: contractListQueryKeyPredicate });
    };

    return (
        <Dialog open={open} onClose={onClose} maxWidth="lg">
            <DialogTitle>
                <Typography variant="textXl" fontWeight={500}>
                    <FormattedMessage defaultMessage="Upload contracts" />
                </Typography>
            </DialogTitle>
            <DialogContent>
                <Stack direction="row" minWidth="485px" pt={1}>
                    {!file && (
                        <DocumentDrop
                            disabled={!hasGeneralWritePermission}
                            allowedExtensions={["zip"]}
                            onChange={async (files) => {
                                if (files.length > 0) {
                                    setFile(files[0]);
                                }
                            }}
                        />
                    )}
                    {file && (
                        <Stack direction="row" alignItems="center" gap={1}>
                            <Typography variant="textMd">{file.name}</Typography>
                            <Typography variant="textMd">
                                <FormattedMessage
                                    defaultMessage="{sizeMb} MB"
                                    values={{
                                        sizeMb: formatNumber(file.size * 10 ** -6, { maximumFractionDigits: 2 }),
                                    }}
                                />
                            </Typography>
                            <IconButton onClick={() => setFile(null)}>
                                <X />
                            </IconButton>
                        </Stack>
                    )}
                </Stack>
                <Stack pt={1}>
                    <Typography variant="textSm" color="textSecondary">
                        <FormattedMessage defaultMessage="Upload a .zip file containing contracts in PDFs, of maximum 15 pages each." />
                    </Typography>
                    <Typography variant="textSm" color="textSecondary">
                        <FormattedMessage defaultMessage="We will do our best to create the contracts in Ignite, using information extracted from the PDFs." />
                    </Typography>
                </Stack>
            </DialogContent>
            <DialogActions>
                <Stack gap={1.5} direction="row" justifyContent="flex-end">
                    <Button onClick={onClose} color="secondary" size="small">
                        <FormattedMessage defaultMessage="Cancel" />
                    </Button>
                    <LoadingButton
                        loading={loading}
                        disabled={!file || !hasGeneralWritePermission}
                        size="small"
                        onClick={handleSubmit}
                    >
                        <FormattedMessage defaultMessage="Upload" />
                    </LoadingButton>
                </Stack>
            </DialogActions>
        </Dialog>
    );
};

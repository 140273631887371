import { Grid2 as Grid, Stack, Typography } from "@mui/material";
import { contractDetailRoute } from "@/Router";
import { Contract } from "@/types";
import { customfieldRenderOrder } from "../utils/customFieldRenderOrder";
import { CustomField } from "@/models/customFields";
import { ContractCustomField } from "../field";

export interface ContractOtherDetailsProps {
    contract: Contract;
    editMode: boolean;
    onCustomFieldChange: (customFields: CustomField[]) => void;
}

export const ContractOtherDetails: React.FC<ContractOtherDetailsProps> = ({
    contract,
    editMode,
    onCustomFieldChange,
}) => {
    const { layout } = contractDetailRoute.useLoaderData();

    const handleCustomFieldChange = (customField: CustomField) => (value: CustomField) => {
        const updated = contract.customFields.map((cf) => (cf.id === customField.id ? value : cf));
        onCustomFieldChange(updated);
    };
    return layout.groups.map((g) => (
        <Stack key={g.id} gap={2}>
            <Typography variant="textLg" fontWeight="500">
                {g.name}
            </Typography>
            <Grid container rowGap={3} columnSpacing={3}>
                {customfieldRenderOrder(contract, g).map((customField) => (
                    <ContractCustomField
                        key={customField.id}
                        edit={editMode}
                        customField={customField}
                        onChange={(e) => handleCustomFieldChange(customField)(e)}
                    />
                ))}
            </Grid>
        </Stack>
    ));
};

import { X } from "@ignite-analytics/icons";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    IconButton,
    Stack,
    Typography,
} from "@mui/material";
import React from "react";
import { FormattedMessage } from "react-intl";
import { Contact, useUsers } from "@/entities/users";
import { ContactsDropdown } from "@/components/ui/ContactDropdown/contactsEdit";

type Props = {
    open: boolean;
    onConfirm: (contractResponsibleIds: string[]) => void;
    onCancel: () => void;
    numContracts: number;
};

const DEFAULT_VALUE: Contact[] = [] as const;
export const BulkSetContractResponsiblesDialog: React.FC<Props> = ({ numContracts, open, onCancel, onConfirm }) => {
    const [contractResponsibleIds, setContractResponsibleIds] = React.useState<string[]>([]);
    const { users } = useUsers();

    const handleConfirm = () => {
        onConfirm(contractResponsibleIds);
        setContractResponsibleIds([]);
    };

    return (
        <Dialog open={open} onClose={onCancel} maxWidth="sm">
            <DialogTitle>
                <Stack direction="row" justifyContent="space-between">
                    <Stack gap={1}>
                        <Typography variant="textXl">
                            <FormattedMessage
                                defaultMessage="Add contract responsible for {numContracts} contracts"
                                values={{ numContracts }}
                            />
                        </Typography>
                        <Typography variant="textSm" color="textTextBody">
                            <FormattedMessage defaultMessage="The selected users will be added as contract responsibles to the selected contracts" />
                        </Typography>
                    </Stack>
                    <IconButton onClick={onCancel}>
                        <X />
                    </IconButton>
                </Stack>
            </DialogTitle>
            <DialogContent>
                <Stack pt={4}>
                    <ContactsDropdown
                        multiple
                        defaultValue={DEFAULT_VALUE}
                        options={users}
                        onChange={(value) => {
                            setContractResponsibleIds(value.map((user) => user.id));
                        }}
                    />
                </Stack>
            </DialogContent>
            <DialogActions>
                <Stack direction="row" gap={1}>
                    <Button onClick={onCancel} color="secondary">
                        <FormattedMessage defaultMessage="Cancel" />
                    </Button>
                    <Button onClick={handleConfirm} disabled={contractResponsibleIds.length === 0} color="primary">
                        <FormattedMessage defaultMessage="Confirm" />
                    </Button>
                </Stack>
            </DialogActions>
        </Dialog>
    );
};

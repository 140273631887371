import { useLazyQuery } from "@apollo/client";
import { ChevronLeft, Contracts, File, PaperAirplane } from "@ignite-analytics/icons";
import { track } from "@ignite-analytics/track";
import { LoadingButton } from "@mui/lab";
import {
    Autocomplete,
    Box,
    Chip,
    CircularProgress,
    IconButton,
    Link,
    Stack,
    TextField,
    Typography,
} from "@mui/material";
import { useInfiniteQuery } from "@tanstack/react-query";
import { useRouter } from "@tanstack/react-router";
import { useCallback, useMemo, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useDebounce } from "use-debounce";
import { graphql } from "@/generated";
import { useSessionContext } from "@/contexts/SessionContext";
import { AppLayout } from "@/components/Layout/AppLayout";
import { Attachment, useAttachments } from "@/components/FileAttachments/hooks";
import { loadContracts } from "../list/loaders";
import { PromptSuggestionChip } from "./PromptSuggestionChip";

const askDocumentQuestionDocument = graphql(`
    query contracts_askDocumentQuestion($input: AskDocumentQuestionRequest!) {
        askDocumentQuestion(input: $input) {
            answer
        }
    }
`);

type Contract = {
    id: string;
    name: string;
};
const PAGE_SIZE = 100;
export const AskDocumentExperimentPage: React.FC = () => {
    const [contract, setContract] = useState<Contract | null>(null);
    const [attachment, setAttachment] = useState<Attachment | null>(null);
    const [conversationHistory, setConversationHistory] = useState<{ message: string; type: "answer" | "question" }[]>(
        []
    );
    const [prompt, setPrompt] = useState("");
    const { history } = useRouter();

    const attachments = useAttachments(contract?.id || "");
    const [searchTerm, setSearchTerm] = useState("");
    const { formatMessage } = useIntl();
    const { tenant } = useSessionContext();

    const [debouncedSearchTerm] = useDebounce(searchTerm, 500);
    const paginatedContractsData = useInfiniteQuery({
        enabled: true,
        queryKey: [tenant, "contracts", { searchTerm: debouncedSearchTerm }],
        initialPageParam: 0,
        queryFn: ({ pageParam = 1 }) =>
            loadContracts({
                status: "all",
                pagination: { page: pageParam, perPage: PAGE_SIZE },
                sort: undefined,
                filters: [],
                searchTerm: debouncedSearchTerm,
            }),
        getNextPageParam: (lastPage, allPages, lastPageParam) =>
            lastPage.contracts.total > lastPage.contracts.data.length + allPages.length * PAGE_SIZE
                ? lastPageParam + 1
                : undefined,
    });

    const [askDocumentQuestion, { loading, called }] = useLazyQuery(askDocumentQuestionDocument);

    const handleAskQuestion = useCallback(
        (question: string) => {
            if (!attachment?.url || !question.length) {
                return;
            }
            setConversationHistory((prev) => [...prev, { message: question, type: "question" }]);
            setPrompt("");
            track("Contract: Asked question experiment", { question });
            askDocumentQuestion({
                variables: {
                    input: {
                        fileURL: attachment.url,
                        question,
                    },
                },
                onCompleted: (data) => {
                    setConversationHistory((prev) => [
                        ...prev,
                        { message: data.askDocumentQuestion.answer, type: "answer" },
                    ]);
                },
            });
        },
        [askDocumentQuestion, attachment?.url]
    );

    const contracts: Contract[] = useMemo(() => {
        return (
            paginatedContractsData.data?.pages
                .flatMap((page) => page.unmapped)
                ?.map((contract) => ({
                    id: contract.id,
                    name: contract.title,
                })) || []
        );
    }, [paginatedContractsData.data?.pages]);

    const suggestionChipDisabled = !attachment || !contract;
    return (
        <AppLayout>
            <Stack spacing={1}>
                <Stack direction="row" spacing={2} pb={2}>
                    <IconButton
                        role="button"
                        aria-label="back"
                        onClick={() => {
                            history.back();
                        }}
                    >
                        <ChevronLeft />
                    </IconButton>
                    <Stack gap={1}>
                        <Stack direction="row" alignItems="center" gap={2}>
                            <Typography variant="displayXs" fontWeight={700}>
                                <FormattedMessage defaultMessage="AI-powered contract Q&A" />
                            </Typography>
                            <Chip label="BETA" color="ignite" />
                        </Stack>
                        <Stack direction="row" alignItems="center">
                            <Typography variant="textSm" color="textTextBody">
                                <FormattedMessage defaultMessage="Get instant answers and insights from your contract documents" />
                            </Typography>
                        </Stack>
                    </Stack>
                </Stack>
                <Autocomplete
                    value={contract}
                    size="small"
                    fullWidth
                    onInputChange={(e, value) => {
                        // One of the possible solutions to prevent executing onInputChange while onChange getting executed.
                        e && e.constructor.name === "SyntheticEvent" && setSearchTerm(value);
                    }}
                    onChange={(_, value) => {
                        setContract(value);
                        setAttachment(null);
                    }}
                    options={contracts}
                    getOptionLabel={(option) => option.name}
                    isOptionEqualToValue={(option, value) => option.id === value.id}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            slotProps={{
                                input: {
                                    ...params.InputProps,
                                    placeholder: formatMessage({
                                        defaultMessage: "Type a contract title you want to work with",
                                    }),
                                    size: "small",
                                    startAdornment: <Contracts />,
                                    endAdornment: paginatedContractsData.isLoading ? (
                                        <CircularProgress color="inherit" size={20} />
                                    ) : null,
                                },
                            }}
                        />
                    )}
                    ListboxProps={{
                        sx: {
                            maxHeight: 350,
                        },
                        onScroll: (event: React.SyntheticEvent) => {
                            const listboxNode = event.currentTarget;
                            if (
                                Math.round(listboxNode.scrollTop + listboxNode.clientHeight) ===
                                listboxNode.scrollHeight
                            ) {
                                paginatedContractsData.fetchNextPage();
                            }
                        },
                    }}
                />

                <Autocomplete
                    value={attachment}
                    size="small"
                    fullWidth
                    onChange={(_, value) => {
                        setAttachment(value);
                    }}
                    options={attachments.data}
                    disabled={!contract}
                    getOptionLabel={(option) => option.name}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            slotProps={{
                                input: {
                                    ...params.InputProps,
                                    placeholder: formatMessage({
                                        defaultMessage: "Select a document within contract to ask questions about",
                                    }),
                                    size: "small",
                                    startAdornment: <File />,
                                    endAdornment: attachments.loading ? (
                                        <CircularProgress color="inherit" size={20} />
                                    ) : (
                                        attachment?.url && (
                                            <Link href={attachment.url} target="_blank" sx={{ textDecoration: "none" }}>
                                                <FormattedMessage defaultMessage="OPEN" />
                                            </Link>
                                        )
                                    ),
                                },
                            }}
                        />
                    )}
                />
            </Stack>
            <Stack gap={2} py={3} height="100%" overflow="auto" justifyContent="start">
                {conversationHistory.map(({ message, type }) => (
                    <Stack key={message} direction="row" justifyContent={type === "answer" ? "start" : "end"}>
                        <Chip
                            label={
                                <Box sx={{ p: 1 }}>
                                    <Typography variant="textSm" sx={{ whiteSpace: "normal" }}>
                                        {message}
                                    </Typography>
                                </Box>
                            }
                            color={type === "answer" ? "infoAccent" : "infoGray"}
                            sx={{ maxWidth: "50%", height: "auto" }}
                        />
                    </Stack>
                ))}
            </Stack>
            <Stack gap={3}>
                {!called && (
                    <Stack justifyContent="end" gap={2}>
                        <PromptSuggestionChip
                            suggestion={formatMessage({
                                defaultMessage: "What are the payment terms and due dates for this contract?",
                            })}
                            disabled={suggestionChipDisabled}
                            onClick={handleAskQuestion}
                        />
                        <PromptSuggestionChip
                            suggestion={formatMessage({
                                defaultMessage: "What are the conditions for termination or renewal of this contract?",
                            })}
                            disabled={suggestionChipDisabled}
                            onClick={handleAskQuestion}
                        />
                        <PromptSuggestionChip
                            suggestion={formatMessage({
                                defaultMessage:
                                    "What confidentiality or data protection clauses are included in this contract?",
                            })}
                            disabled={suggestionChipDisabled}
                            onClick={handleAskQuestion}
                        />
                        <PromptSuggestionChip
                            suggestion={formatMessage({ defaultMessage: "Summarize this contract" })}
                            disabled={suggestionChipDisabled}
                            onClick={handleAskQuestion}
                        />
                    </Stack>
                )}
                <Stack direction="row" gap={1}>
                    <TextField
                        placeholder={
                            called
                                ? formatMessage({ defaultMessage: "Ask another question..." })
                                : formatMessage({ defaultMessage: "Type a question here..." })
                        }
                        value={prompt}
                        onChange={(e) => setPrompt(e.target.value)}
                        fullWidth
                        onKeyDown={(e) => {
                            if (e.key === "Enter") {
                                e.preventDefault();
                                handleAskQuestion(prompt);
                            }
                        }}
                        disabled={!attachment || !contract}
                    />
                    <LoadingButton
                        onClick={() => handleAskQuestion(prompt)}
                        color="secondary"
                        size="small"
                        disabled={!attachment || !prompt || !contract}
                        loading={loading}
                    >
                        <Stack direction="row" gap={1} alignItems="center">
                            <PaperAirplane fontSize="small" />
                            <FormattedMessage defaultMessage="Ask" />
                        </Stack>
                    </LoadingButton>
                </Stack>
            </Stack>
        </AppLayout>
    );
};

import { useState } from "react";
import { useIntl } from "react-intl";

import { AccessOverview } from "@/components/AccessOverview";
import { ManageAccessPopup } from "@/components/ManageAccessPopup/manageAccessPopup";
import { useContractAccessors } from "@/hooks/useContractAccessors";
import { contractDetailRoute } from "@/Router";
import { Contract } from "@/types";
import { getContractById } from "../../loaders";
import { SidebarCard } from "./SidebarCard";

export interface AccessCardProps {
    contract: Contract;
    canEdit: boolean;
    editMode: boolean;
    setContract: (contract: Contract) => void;
}

export const AccessCard: React.FC<AccessCardProps> = ({ contract, editMode, canEdit, setContract }) => {
    const { id } = contractDetailRoute.useParams();
    const [manageAccessPopupOpen, setManageAccessPopupOpen] = useState(false);

    const { formatMessage } = useIntl();

    const restricted = contract?.isPrivate ?? false;
    const responsible = contract?.contractResponsibleIds;
    const { accessors, refetch } = useContractAccessors(id, restricted, responsible);

    return (
        <>
            <SidebarCard
                label={formatMessage({ defaultMessage: "Access to this contract" })}
                edit={editMode}
                onClickEdit={() => {
                    setManageAccessPopupOpen(true);
                }}
            >
                <AccessOverview
                    restricted={contract?.isPrivate}
                    canEdit={canEdit}
                    onEdit={() => setManageAccessPopupOpen(true)}
                    accessors={accessors}
                />
            </SidebarCard>
            <ManageAccessPopup
                onClose={() => setManageAccessPopupOpen(false)}
                isOpen={manageAccessPopupOpen}
                contractIds={[id]}
                updateContracts={async () => {
                    setContract(await getContractById(id));
                    await refetch();
                }}
                defaultAccess={contract?.isPrivate ? "restricted" : "public"}
            />
        </>
    );
};

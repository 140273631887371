import { track } from "@ignite-analytics/track";
import { Alert, Skeleton, Stack, Typography } from "@mui/material";
import { useState } from "react";
import { Bar, BarChart, CartesianGrid, Legend, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts";

import { fm } from "@/contexts/IntlContext";
import { formatBigNumber } from "@/helpers/formatNumbers";
import globalMessages from "@/messages";
import { ContractCoverageData, TimePeriod } from "..";
import { CustomTooltip } from "./CustomTooltip";
import messages from "./messages";

const CHART_HEIGHT = 400;

type Props = {
    data: ContractCoverageData | undefined;
    onTimePeriodChange: (period: TimePeriod) => void;
    onYearSelected: (year: string) => void;
    period: TimePeriod;
    loading: boolean;
    error: string | undefined;
    spendThroughContractsKey: string;
    spendWoContractsKey: string;
    hasSpendTable: boolean;
    hasConnectedContracts: boolean;
};

type BarGraphData = {
    x: number;
    y: number;
    label: string;
};

// the legend in recharts will have the same fill color as the bar, so we override it with a custom formatter
// to make sure we have black color.
const LegendFormatter = ({ value }: { value: string }) => {
    return (
        <Typography variant="textSm" component="span">
            {value}
        </Typography>
    );
};

export const InnerChart: React.FC<Props> = ({
    data,
    loading,
    period,
    onYearSelected,
    onTimePeriodChange,
    error,
    spendThroughContractsKey,
    spendWoContractsKey,
    hasSpendTable,
    hasConnectedContracts,
}) => {
    const [barGraphData, setBarGraphData] = useState<BarGraphData>({
        x: 0,
        y: 0,
        label: "",
    });
    if (loading && !data) {
        return <Skeleton variant="rounded" height={`${CHART_HEIGHT}px`} />;
    }

    if (!hasSpendTable) {
        return (
            <Stack height={CHART_HEIGHT} justifyContent="center" alignItems="center">
                <Alert color="info">{fm(messages.noSpend)}</Alert>
            </Stack>
        );
    }

    if (!hasConnectedContracts) {
        return (
            <Stack height={CHART_HEIGHT} justifyContent="center" alignItems="center">
                <Alert color="info">{fm(messages.notConnectedToContracts)}</Alert>
            </Stack>
        );
    }

    if (error || !data) {
        return (
            <Stack height={CHART_HEIGHT} justifyContent="center" alignItems="center">
                <Alert color="error"> {fm(globalMessages.somethingWentWrong)}</Alert>
            </Stack>
        );
    }

    const handleBarClick = (_e: any, data: { label: string }) => {
        if (period !== "year") {
            return;
        }
        onYearSelected(data.label);
        onTimePeriodChange("quarter");
    };

    return (
        <ResponsiveContainer height={CHART_HEIGHT}>
            <BarChart data={data}>
                <Tooltip
                    content={<CustomTooltip />}
                    cursor={{ fill: "#eeeeee" }}
                    formatter={(v) => Math.floor(Number(v)).toLocaleString()}
                    position={{ x: barGraphData.x - 120, y: barGraphData.y - 120 }}
                />
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="label" />
                <YAxis tickFormatter={(v) => formatBigNumber(v, "auto")} />
                <Bar
                    dataKey={spendThroughContractsKey}
                    stackId="1"
                    fill="#151D4C"
                    barSize={60}
                    onClick={(data, _, e) => handleBarClick(e, data)}
                    cursor={period === "year" ? "pointer" : "default"}
                    onMouseOver={(data) => {
                        setBarGraphData(data);
                        track("Contract Overview: Contract coverage bar hover", { label: data.label });
                    }}
                />
                <Bar
                    dataKey={spendWoContractsKey}
                    stackId="1"
                    fill="#069CDF"
                    barSize={60}
                    onClick={(data, _, e) => handleBarClick(e, data)}
                    cursor={period === "year" ? "pointer" : "default"}
                    onMouseOver={(data) => {
                        setBarGraphData(data);
                        track("Contract Overview: Contract coverage bar hover", { label: data.label });
                    }}
                />
                {/* eslint-disable-next-line react/no-unstable-nested-components */}
                <Legend iconType="circle" formatter={(value) => <LegendFormatter value={value} />} />
            </BarChart>
        </ResponsiveContainer>
    );
};

import { Stack, Tooltip, Typography } from "@mui/material";
import { FormattedDate, FormattedMessage } from "react-intl";
import { Row } from "../../contractListItem";
import { getStatusChipContent } from "./getStatusChipContent";

interface ContractStatusCellProps {
    row: Row;
}

export const ContractStatusCell: React.FC<ContractStatusCellProps> = ({ row }) => {
    const field = row.fields.find((field) => field.dataType === "END_DATE");
    const endDate = field?.data ? new Date(String(field.data)) : null;

    if (!endDate) {
        return (
            <Typography variant="textSm" color="textTextHelper">
                <FormattedMessage defaultMessage="Not added" />
            </Typography>
        );
    }

    const { chip, tooltipTitle } = getStatusChipContent(endDate);

    return (
        <Tooltip
            title={
                <Stack>
                    <Typography variant="textSm">
                        <Stack>
                            <FormattedMessage
                                defaultMessage="End date: {endDate}"
                                values={{
                                    endDate: (
                                        <FormattedDate
                                            value={endDate ?? undefined}
                                            day="2-digit"
                                            month="short"
                                            year="numeric"
                                        />
                                    ),
                                }}
                            />
                        </Stack>
                        <Typography variant="textSm">{tooltipTitle}</Typography>
                    </Typography>
                </Stack>
            }
        >
            {/* Need a div here to make the tooltip work */}
            <div>{chip}</div>
        </Tooltip>
    );
};

import React from "react";
import { Stack, Typography, Link as MuiLink } from "@mui/material";
import { Link } from "@tanstack/react-router";

import { contractListRoute, contractDetailRoute } from "@/Router";

type Props = {
    contractId: string;
    title: string;
};

export const TitleCustomField: React.FC<Props> = ({ contractId, title }) => {
    return (
        <Stack justifyContent="center" alignItems="start" height="100%">
            <MuiLink
                component={Link}
                from={contractListRoute.fullPath}
                to={contractDetailRoute.fullPath}
                params={{ id: contractId }}
                noWrap
                sx={{
                    width: "100%",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                    "&:hover": {
                        textDecorationColor: (theme) => theme.palette.text.primary,
                    },
                }}
            >
                <Typography color="textPrimary" fontWeight="600" variant="textSm">
                    {title}
                </Typography>
            </MuiLink>
        </Stack>
    );
};

import { ShapeIcon } from "@ignite-analytics/components";
import { InformationCircleSolid, X } from "@ignite-analytics/icons";
import { Card, Collapse, IconButton, Stack } from "@mui/material";

interface TopSnackbarProps {
    message: React.ReactNode;
    onClose?: () => void;
}

export const TopSnackbar: React.FC<TopSnackbarProps> = ({ message, onClose }) => {
    return (
        <Collapse in collapsedSize={0} mountOnEnter unmountOnExit orientation="vertical">
            <Stack px={3} pt={2}>
                <Card sx={{ backgroundColor: "#EFFCF2", borderColor: "#7BE79C" }}>
                    <Stack direction="row" alignItems="center" justifyContent="space-between">
                        <Stack direction="row" alignItems="center">
                            <Stack p={2}>
                                <ShapeIcon variant="outline" color="success" size="xsmall">
                                    <InformationCircleSolid color="success" fontSize="large" />
                                </ShapeIcon>
                            </Stack>
                            <Stack p={1.5} spacing={0.75}>
                                {message}
                            </Stack>
                        </Stack>
                        <Stack px={1}>
                            <IconButton size="small" onClick={onClose}>
                                <X />
                            </IconButton>
                        </Stack>
                    </Stack>
                </Card>
            </Stack>
        </Collapse>
    );
};

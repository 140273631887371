import { FormattedMessage, useIntl } from "react-intl";
import { useCallback, useEffect, useState } from "react";
import { useQuery } from "@tanstack/react-query";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    List,
    Skeleton,
    Stack,
    TextField,
    Typography,
} from "@mui/material";
import { defaultLayoutQueryKey } from "@/querykeys";
import { queryClient } from "@/contexts";
import { createLayout, getLayouts, removeLayout, renameLayout, setAsDefaultLayout } from "@/hooks/useContractLayouts";
import { LayoutListItem } from "@/models/layout";
import { LayoutItem } from "./LayoutItem";

type Props = {
    open: boolean;
    onClose: () => void;
};

export const LayoutsPopup = ({ open, onClose }: Props) => {
    const tenant = localStorage.getItem("tenant") ?? "";
    const { formatMessage } = useIntl();
    const [newLayoutItem, setNewLayoutItem] = useState(false);
    const [name, setName] = useState<string>();

    const layoutsQueryData = useQuery({
        enabled: true,
        queryKey: [tenant, "layouts", "list"],
        queryFn: getLayouts,
        staleTime: 1000 * 1 * 15,
    });

    const layouts = layoutsQueryData.data?.layouts ?? [];
    const loading = layoutsQueryData.isLoading;
    const defaultId = layoutsQueryData.data?.defaultLayoutId;
    const refetchQuery = layoutsQueryData.refetch;

    useEffect(() => {
        if (open) {
            refetchQuery();
        }
    }, [open, refetchQuery]);

    const abort = () => {
        setNewLayoutItem(false);
    };

    const handleCreateNewLayout = useCallback(
        (e: React.FormEvent<HTMLFormElement>) => {
            e.preventDefault();
            if (name) {
                createLayout(name)
                    .then(() => refetchQuery())
                    .finally(() => {
                        setName(undefined);
                        setNewLayoutItem(false);
                    });
            }
        },
        [name, refetchQuery]
    );

    const handleSetDefault = async (id: string) => {
        await setAsDefaultLayout(id);
        layoutsQueryData.refetch();
        queryClient.removeQueries({ queryKey: defaultLayoutQueryKey(tenant) });
    };

    const handleDelete = async (id: string) => {
        removeLayout(id).then(() => layoutsQueryData.refetch());
    };

    const handleRename = async (listItem: LayoutListItem) => {
        renameLayout(listItem.id, listItem.name).then(() => layoutsQueryData.refetch());
    };

    return (
        <Dialog open={open} onClose={onClose}>
            <DialogContent>
                <Stack gap={3} minWidth="485px">
                    <Typography variant="textXl" fontWeight={500}>
                        <FormattedMessage defaultMessage="Layouts" />
                    </Typography>
                    <DialogContentText>
                        <FormattedMessage defaultMessage="You can edit contract layouts. Be aware editing and assigning layouts as default will influence your entire organisation" />
                    </DialogContentText>
                    <List>
                        {loading && (
                            <Stack gap={3}>
                                <Skeleton variant="rounded" height={48} />
                                <Skeleton variant="rounded" height={48} />
                            </Stack>
                        )}

                        {!loading &&
                            layouts.map((layout) => (
                                <LayoutItem
                                    layoutListItem={layout}
                                    key={layout.id}
                                    label={formatMessage({ defaultMessage: "Layout name" })}
                                    updateLayout={handleRename}
                                    deleteLayout={handleDelete}
                                    isDefault={defaultId === layout.id}
                                    setAsDefault={handleSetDefault}
                                />
                            ))}
                    </List>
                </Stack>
            </DialogContent>
            <DialogActions>
                <Stack width="100%" justifyItems="end">
                    {newLayoutItem && (
                        <Stack direction="row" gap={3} component="form" onSubmit={handleCreateNewLayout}>
                            <TextField
                                size="small"
                                required
                                onChange={({ target }) => setName(target.value)}
                                autoFocus
                                placeholder={formatMessage({ defaultMessage: "Layout name" })}
                                fullWidth
                            />
                            <Stack direction="row" gap={2}>
                                <Button size="small" color="secondary" onClick={abort}>
                                    <FormattedMessage defaultMessage="Cancel" />
                                </Button>
                                <Button size="small" type="submit">
                                    <FormattedMessage defaultMessage="Create" />
                                </Button>
                            </Stack>
                        </Stack>
                    )}
                    {!newLayoutItem && (
                        <Stack direction="row" gap={3}>
                            <Button sx={{ ml: "auto" }} size="small" onClick={() => setNewLayoutItem(true)}>
                                <Typography>
                                    {formatMessage({ defaultMessage: "Create", id: "layouts.create-new" })}
                                </Typography>
                            </Button>
                        </Stack>
                    )}
                </Stack>
            </DialogActions>
        </Dialog>
    );
};

import { useNavigate } from "@tanstack/react-router";
import { contractListRoute } from "@/Router";

export function usePagination({ setLoading }: { setLoading: (loading: boolean) => void }) {
    const { page, perPage: pageSize } = contractListRoute.useSearch();
    const navigate = useNavigate();

    const handlePaginationChange = ({ page: newPage, pageSize: newPageSize }: { page: number; pageSize: number }) => {
        setLoading(true);
        navigate({ search: (prev) => ({ ...prev, page: newPage + 1, perPage: newPageSize }) }).finally(() => {
            setLoading(false);
        });
    };

    return { page: page - 1, pageSize, onPaginationChange: handlePaginationChange };
}

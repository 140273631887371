import { CircleSolid } from "@ignite-analytics/icons";
import { Chip } from "@mui/material";
import { FormattedMessage } from "react-intl";

export const ExpiredChip: React.FC = () => {
    return (
        <Chip
            variant="status"
            iconSize="small"
            size="small"
            icon={<CircleSolid />}
            color="error"
            label={<FormattedMessage defaultMessage="Expired" />}
        />
    );
};

export const ExpiringInMonthsChip: React.FC<{ months: number }> = ({ months }) => {
    return (
        <Chip
            variant="status"
            iconSize="small"
            color="warning"
            size="small"
            icon={<CircleSolid />}
            label={
                <FormattedMessage
                    defaultMessage="Expiring in {months} {months, plural, one {month} other {months}}"
                    values={{ months }}
                />
            }
        />
    );
};

export const ExpiringInDaysChip: React.FC<{ days: number }> = ({ days }) => {
    return (
        <Chip
            variant="status"
            iconSize="small"
            color="warning"
            size="small"
            icon={<CircleSolid />}
            label={
                <FormattedMessage
                    defaultMessage="Expiring in {days} {days, plural, one {day} other {days}}"
                    values={{ days }}
                />
            }
        />
    );
};

export const ActiveChip: React.FC = () => {
    return (
        <Chip
            variant="status"
            iconSize="small"
            color="success"
            size="small"
            icon={<CircleSolid />}
            label={<FormattedMessage defaultMessage="Active" />}
        />
    );
};

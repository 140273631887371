import { Plus } from "@ignite-analytics/icons";
import { GridActionsCellItem, GridColDef, GridColumnHeaderParams, GridRenderCellParams } from "@mui/x-data-grid-pro";
import { Chip, Typography } from "@mui/material";
import { makeMessages } from "src/components/ContractsTablePro";
import { ColumnHeader } from "./column";
import { Row } from "./contractListItem";
import { format } from "./valueFormatter";

import { COLUMNS_WIHT_DISABLED_MENU, NOT_RESIZABLE_COLUMNS, NOT_SORTABLE_COLUMNS } from "./constants";
import { RenderCustomField } from "./renderCustomField";
import { ContractStatusCell } from "./renderCustomField/ContractStatusCell";
import { CONTRACT_STATUS_COLUMN_ID } from "@/routes/list/columns/generatedColumnIds";

const isColumnSortable = (headerId: string) => !NOT_SORTABLE_COLUMNS.includes(headerId);
const isColumnMenuDisabled = (headerId: string) => COLUMNS_WIHT_DISABLED_MENU.includes(headerId);
const isColumnResizable = (headerId: string) => !NOT_RESIZABLE_COLUMNS.includes(headerId);

const renderHeader = (headerId: string, label: string) => {
    if (headerId === "newcolumn") {
        return <GridActionsCellItem label="Add" icon={<Plus fontSize="small" />} disabled={false} />;
    }
    return (
        <Typography variant="textSm" fontWeight={600}>
            {label}
        </Typography>
    );
};

export const headerToMuiColumn = (header: ColumnHeader): GridColDef<Row> & { fieldType: string } => {
    const { columnMessages } = makeMessages();

    return {
        field: header.id,
        fieldType: header.fieldType,
        headerName: header.label,
        align: header.align,
        sortable: isColumnSortable(header.id),
        width: header.width,
        disableColumnMenu: isColumnMenuDisabled(header.id),
        resizable: isColumnResizable(header.id),
        renderHeader: (_params: GridColumnHeaderParams<Row>) => renderHeader(header.id, header.label),
        valueGetter: (_, row) => {
            const cell = row.fields.find((field: any) => field.id === header.id);
            return cell ? format(cell) : "";
        },
        renderCell: (params: GridRenderCellParams<Row>) => {
            if (header.id === CONTRACT_STATUS_COLUMN_ID) {
                return <ContractStatusCell row={params.row} />;
            }
            const cell = params.row.fields.find((field) => field.id === header.id);
            if (header.id === "isprivate") {
                return (
                    <Chip
                        sx={{ m: 0.5 }}
                        label={params.row.isPrivate ? columnMessages.privateLabel : columnMessages.publicLabel}
                        color={params.row.isPrivate ? "warning" : "success"}
                        size="small"
                    />
                );
            }
            return cell ? <RenderCustomField field={cell} /> : <></>;
        },
    };
};

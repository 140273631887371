import { Chip, Stack, Typography } from "@mui/material";
import { FormattedMessage } from "react-intl";

type GridToolbarTotalItemsContainerProps = {
    rowCount: number;
    selectedSuppliersCount: number | null;
};

export const GridToolbarTotalItemsContainer: React.FC<GridToolbarTotalItemsContainerProps> = ({
    rowCount,
    selectedSuppliersCount,
}) => {
    return (
        <Stack gap={0.5}>
            <Stack direction="row" alignItems="center" gap={1}>
                <Typography variant="textMd" fontWeight={500} noWrap>
                    <FormattedMessage
                        defaultMessage="Total items"
                        description="Total items grid table toolbar component text"
                    />
                </Typography>

                <Chip size="xsmall" label={rowCount} sx={{ minWidth: 45 }} />
            </Stack>
            <Typography variant="textXs" color="textTextHelper">
                {selectedSuppliersCount !== null ? (
                    <FormattedMessage
                        defaultMessage="{count, plural, one {# contract selected} other {# contracts selected}}"
                        values={{ count: selectedSuppliersCount }}
                    />
                ) : null}
            </Typography>
        </Stack>
    );
};

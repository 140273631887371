import React, { useMemo } from "react";
import { useDebounce } from "use-debounce";
import { Stack, Autocomplete, CircularProgress, TextField } from "@mui/material";
import { Truck } from "@ignite-analytics/icons";
import { useIntl } from "react-intl";
import { useQuery } from "@apollo/client";
import { graphql } from "@/generated";

const SupplierDropdown_GetSuppliers = graphql(/* GraphQL */ `
    query UseGetSupplierTableRowsByNameSearch_SupplierNameSearch($input: GetSuppliersInput!) {
        getSuppliers(input: $input) {
            suppliers {
                id
                name
            }
        }
    }
`);

type SupplierRow = {
    id: string;
    name: string;
};

type Props = {
    value: SupplierRow | null;
    onChange: (value: SupplierRow | null) => void;
    fullWidth?: boolean;
    label?: string;
    required?: boolean;
    maxWidth?: number;
};

export const SupplierDropDownV2 = ({ value, onChange, fullWidth, label, required }: Props) => {
    const [search, setSearch] = React.useState<string>("");
    const { formatMessage } = useIntl();

    const [debouncedSearch] = useDebounce(search, 500);

    const { data, loading } = useQuery(SupplierDropdown_GetSuppliers, {
        // If the search is the same as the value, set the nameSearch to undefined
        // To show all supplier options
        variables: { input: { nameSearch: debouncedSearch === value?.name ? undefined : debouncedSearch } },
    });

    const options = useMemo(() => {
        return data?.getSuppliers.suppliers ?? [];
    }, [data?.getSuppliers.suppliers]);

    return (
        <Autocomplete
            value={value}
            size="small"
            fullWidth={fullWidth}
            onInputChange={(_, value) => {
                setSearch(value);
            }}
            onChange={(_, value) => {
                onChange(value);
            }}
            blurOnSelect
            options={options}
            getOptionLabel={(option) => option.name}
            renderOption={(props, option) => (
                <Stack component="li" gap={2} direction="row" alignItems="center" {...props} key={option.id}>
                    <Truck fontSize="inherit" />
                    {option.name}
                </Stack>
            )}
            renderInput={(params) => (
                <TextField
                    {...params}
                    required={required}
                    label={label}
                    slotProps={{
                        input: {
                            ...params.InputProps,
                            placeholder: formatMessage({ defaultMessage: "Search for supplier" }),
                            startAdornment: <Truck sx={{ pl: 1 }} fontSize="small" />,
                            size: "small",
                            endAdornment: (
                                <>
                                    {loading ? <CircularProgress color="inherit" size={20} /> : null}
                                    {params.InputProps.endAdornment}
                                </>
                            ),
                        },
                    }}
                />
            )}
        />
    );
};

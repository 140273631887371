import { Tooltip, Typography, TypographyProps } from "@mui/material";
import { useRef, useState, useEffect, ReactNode } from "react";

const isEllipsisActive = (elementRef: React.RefObject<HTMLDivElement>) => {
    if (!elementRef?.current?.scrollWidth || !elementRef?.current?.clientWidth) {
        return false;
    }
    return elementRef.current.scrollWidth > elementRef.current.clientWidth;
};
interface EllipticTypographyProps extends TypographyProps {
    children: ReactNode;
}

export const EllipticTypography: React.FC<EllipticTypographyProps> = ({ children, ...props }) => {
    const typographyRef = useRef<HTMLDivElement>(null);
    const [ellipsisActive, setEllipsisActive] = useState(false);
    const checkEllipsis = () => {
        setEllipsisActive(isEllipsisActive(typographyRef));
    };
    useEffect(() => {
        checkEllipsis();
        window.addEventListener("resize", checkEllipsis);
        return () => {
            window.removeEventListener("resize", checkEllipsis);
        };
    }, [children]);
    useEffect(() => {
        checkEllipsis();
    }, [children]);
    return (
        <Tooltip
            disableHoverListener={!ellipsisActive}
            disableFocusListener={!ellipsisActive}
            disableTouchListener={!ellipsisActive}
            title={<Typography {...props}>{children}</Typography>}
        >
            <Typography {...props} textOverflow="ellipsis" noWrap ref={typographyRef}>
                {children}
            </Typography>
        </Tooltip>
    );
};

import { useMemo } from "react";
import { useIntl } from "react-intl";

import { useUsers } from "@/entities/users";
import { Contract } from "@/types";
import { ContractResponsibleDisplay } from "@/components/UserDisplay/ContractResponsibleView";
import { ContactsDropdown } from "@/components/ui/ContactDropdown/contactsEdit";
import { hasValue } from "@/helpers/hasValue";
import { SidebarCard } from "./SidebarCard";

export interface ContractResponsibleCardProps {
    contract: Contract;
    editMode: boolean;
    setContract: (contract: Contract) => void;
}

export const ContractResponsibleCard: React.FC<ContractResponsibleCardProps> = ({
    contract,
    editMode,
    setContract,
}) => {
    const { formatMessage } = useIntl();

    const { users } = useUsers();
    const contractResponsibleUsers = useMemo(() => {
        return (contract?.contractResponsibleIds || [])
            .map((id) => users.find((user) => user.id === id))
            .filter(hasValue);
    }, [contract, users]);

    return (
        <SidebarCard
            label={formatMessage({ defaultMessage: "Contract responsible" })}
            blank={contractResponsibleUsers.length === 0}
            edit={editMode}
        >
            <ContractResponsibleDisplay users={contractResponsibleUsers} max={5} />
            <ContactsDropdown
                multiple
                defaultValue={contractResponsibleUsers}
                options={users}
                onChange={(e) => {
                    setContract({
                        ...contract,
                        contractResponsibleIds: e.map((user) => user.id),
                    });
                }}
            />
        </SidebarCard>
    );
};

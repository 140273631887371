import { Avatar, Stack, Typography } from "@mui/material";
import { User } from "@/entities/users";

interface UserItemProps {
    user: Pick<User, "id" | "firstName" | "lastName"> | undefined | null;
    disabled?: boolean;
}

export const UserItem: React.FC<UserItemProps> = ({ user, disabled }) => {
    if (!user) {
        return null;
    }

    return (
        <Stack direction="row" alignItems="center" spacing={1}>
            <Avatar src="" sx={{ width: 24, height: 24 }}>
                <Typography
                    color={disabled ? "textTextDisabled" : "textSecondary"}
                    variant="textXs"
                >{`${user.firstName[0]}${user.lastName[0]}`}</Typography>
            </Avatar>
            <Typography
                color={disabled ? "textTextDisabled" : "textSecondary"}
                variant="textSm"
                fontWeight="400"
                noWrap
            >
                {`${user.firstName} ${user.lastName}`}
            </Typography>
        </Stack>
    );
};

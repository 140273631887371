import { Popover, Stack, Typography } from "@mui/material";
import { RichTreeView, TreeItemProps, TreeViewBaseItem, useTreeViewApiRef } from "@mui/x-tree-view";
import { FormattedMessage } from "react-intl";
import { Search } from "@ignite-analytics/icons";

import { useMemo, useRef, useState } from "react";
import { buildTree, getItem } from "../utils";
import { TreeItem } from "./TreeItem";
import { LeafNode } from "../types";
import { SearchField } from "@/components/SearchField";

interface TreeSelectorPopperProps {
    open: boolean;
    onClose: () => void;
    anchorEl: null | HTMLElement;
    value: string[];
    items: LeafNode[];
    onChange: (value: string[]) => void;
    multiSelect?: boolean;
}

export const TreeSelectorPopper: React.FC<TreeSelectorPopperProps> = ({
    open,
    onClose,
    anchorEl,
    value,
    onChange,
    items,
    multiSelect,
}) => {
    const [expandedItems, setExpandedItems] = useState<string[]>([]);
    const toggledItemRef = useRef<{ [itemId: string]: boolean }>({});
    const apiRef = useTreeViewApiRef();

    const treeStructure: LeafNode[] = useMemo(() => {
        if (!items) {
            return [];
        }

        const treeStructure = buildTree(items);

        return treeStructure;
    }, [items]);

    const minWidth = anchorEl?.getBoundingClientRect().width || 270;

    const [searchTerm, setSearchTerm] = useState("");

    function getItemDescendantsIds(item: TreeViewBaseItem) {
        const ids: string[] = [];
        item.children?.forEach((child) => {
            ids.push(child.id);
            ids.push(...getItemDescendantsIds(child));
        });

        return ids;
    }

    const handleItemSelectionToggle = (_: React.SyntheticEvent, itemId: string, isSelected: boolean) => {
        toggledItemRef.current[itemId] = isSelected;
    };

    const handleSelectedItemsChange = (_: React.SyntheticEvent, newSelectedItems: string | string[] | null) => {
        let items: string[];
        if (Array.isArray(newSelectedItems)) {
            items = newSelectedItems;
        } else if (newSelectedItems === null) {
            items = [];
        } else {
            items = [newSelectedItems];
        }
        onChange(items);

        // Select / unselect the children of the toggled item
        const itemsToSelect: string[] = [];
        const itemsToUnSelect: { [itemId: string]: boolean } = {};
        Object.entries(toggledItemRef.current).forEach(([itemId, isSelected]) => {
            const item = apiRef.current!.getItem(itemId);
            const decendants = getItemDescendantsIds(item);
            if (isSelected) {
                itemsToSelect.push(...decendants);
            } else {
                decendants.forEach((descendantId) => {
                    itemsToUnSelect[descendantId] = true;
                });
                if (item.parentId) {
                    itemsToUnSelect[item.parentId] = true;
                }
            }
        });

        const newSelectedItemsWithChildren = Array.from(
            new Set([...items, ...itemsToSelect].filter((itemId) => !itemsToUnSelect[itemId]))
        );

        onChange(newSelectedItemsWithChildren);

        toggledItemRef.current = {};
    };

    const [filteredTreeStructure, expandedOnSearch] = useMemo(() => {
        if (!searchTerm) {
            return [treeStructure, []];
        }

        let expandedIds: string[] = [];

        const matches: Record<string, LeafNode> = {};

        items.forEach((item) => {
            let parentId = item.parentId;
            if (item.label.toLowerCase().includes(searchTerm.toLowerCase())) {
                matches[item.id] = item;
                while (parentId) {
                    expandedIds = [...expandedIds, parentId];
                    const parent = getItem(treeStructure, parentId);
                    if (parent) {
                        matches[parentId] = parent;
                        parentId = parent.parentId;
                    }
                }
            }
        });

        const tree: LeafNode[] = buildTree(Object.values(matches));

        return [tree, expandedIds];
    }, [searchTerm, treeStructure, items]);

    const getExpandedItems = () => {
        if (searchTerm) {
            return expandedOnSearch;
        }

        return expandedItems;
    };

    return (
        <Popover
            open={open}
            anchorEl={anchorEl}
            onClose={onClose}
            anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
        >
            <Stack minWidth={minWidth} maxHeight="40vh">
                <SearchField
                    searchTerm={searchTerm}
                    setSearchTerm={setSearchTerm}
                    width="100%"
                    placeholder="Search categories"
                    autoFocus
                />
                {!filteredTreeStructure.length ? (
                    <Stack
                        width="100%"
                        height={150}
                        justifyContent="center"
                        alignItems="center"
                        direction="row"
                        spacing={1}
                        color="text.textHelper"
                    >
                        <Search />
                        <Typography>
                            <FormattedMessage defaultMessage="No results..." />
                        </Typography>
                    </Stack>
                ) : (
                    <RichTreeView
                        multiSelect={multiSelect}
                        apiRef={apiRef}
                        items={filteredTreeStructure}
                        checkboxSelection
                        selectedItems={value}
                        // expansionTrigger="iconContainer"
                        slots={{
                            // eslint-disable-next-line
                            item: (props: TreeItemProps) => (
                                <TreeItem {...props} searchTerm={searchTerm} label={props.label as string} />
                            ),
                        }}
                        onItemSelectionToggle={handleItemSelectionToggle}
                        onSelectedItemsChange={handleSelectedItemsChange}
                        expandedItems={getExpandedItems()}
                        onExpandedItemsChange={(_, itemIds) => setExpandedItems(itemIds)}
                    />
                )}
            </Stack>
        </Popover>
    );
};

import { useIntl } from "react-intl";
import { useState } from "react";
import { IconButton, Stack, Typography } from "@mui/material";
import dayjs from "dayjs";
import { Trash } from "@ignite-analytics/icons";
import { Contract } from "@/types";
import { SidebarCard } from "./SidebarCard";
import { contractDetailRoute } from "@/Router";
import { useContractsApiFetch } from "@/hooks/useContractsApiFetch";
import { Alert } from "@/components/Alerts/dumb/models";
import { useContractsApiPost } from "@/hooks/useContractsApiPost";
import { useDeleteAlert } from "@/hooks/useAlerts";
import { AddAlertPopupV2 } from "@/components/Alerts/AddAlertV2";

export interface AlertsCardProps {
    editMode: boolean;
    contract: Contract;
}

export const AlertsCard: React.FC<AlertsCardProps> = ({ editMode, contract }) => {
    const { id } = contractDetailRoute.useParams();
    const { formatMessage } = useIntl();

    // TODO: handle case when supplier is not set
    const alerts = useContractsApiFetch<Alert[]>(`contracts/${id}/reminders`);
    const [alertModalOpen, setAlertModalOpen] = useState(false);
    const apiPost = useContractsApiPost();
    const deleteAlert = useDeleteAlert();

    return (
        <SidebarCard
            edit={editMode}
            label={formatMessage({ defaultMessage: "Alerts" })}
            editLabel={formatMessage({ defaultMessage: "Add new" })}
            onClickEdit={() => {
                setAlertModalOpen(true);
            }}
        >
            <Stack gap={2.5}>
                {(alerts.data || []).map((alert) => (
                    <Stack direction="row" alignItems="center" key={alert.id}>
                        <Stack>
                            <Typography variant="textSm" fontWeight={500}>
                                {alert.message}
                            </Typography>
                            <Typography variant="textSm" color="GrayText" fontWeight={400}>
                                {dayjs(alert.scheduledOn).format("DD MMM YYYY")}
                            </Typography>
                        </Stack>
                        <IconButton
                            sx={{ ml: "auto", visibility: editMode ? "visible" : "hidden" }}
                            onClick={() => deleteAlert(contract.id, alert.id).then(() => alerts.refetch())}
                        >
                            <Trash />
                        </IconButton>
                    </Stack>
                ))}
                <AddAlertPopupV2
                    open={alertModalOpen}
                    onClose={() => {
                        setAlertModalOpen(false);
                    }}
                    onSubmit={async (message, scheduledOn) => {
                        return apiPost(`contracts/${id}/reminders`, {
                            scheduledOn,
                            message,
                            refId: id,
                        }).then(() => {
                            alerts.refetch();
                            setAlertModalOpen(false);
                        });
                    }}
                />
            </Stack>
        </SidebarCard>
    );
};

import { IconButton, Link, Stack, styled, Tooltip, tooltipClasses, TooltipProps, Typography } from "@mui/material";
import React, { useMemo } from "react";
import { ArrowTopRightOnSquare, Clone } from "@ignite-analytics/icons";
import { getBorderToken } from "@ignite-analytics/theme";
import { FormattedMessage } from "react-intl";
import { useSnackbar } from "@/contexts/useSnackbar";

const TooltipContent: React.FC<{ text: string; isLink: boolean }> = ({ text, isLink }) => {
    const { postSnackbar } = useSnackbar();
    return (
        <Stack direction="row" gap={1} alignItems="center">
            <Typography noWrap variant="textSm" color="textTextBody">
                {text}
            </Typography>
            <IconButton
                aria-label="Copy to clipboard"
                onClick={() => {
                    navigator.clipboard.writeText(text);
                    postSnackbar({
                        message: <FormattedMessage defaultMessage="Copied to clipboard" />,
                    });
                }}
                size="2xsmall"
            >
                <Clone color="inherit" />
            </IconButton>
            {isLink && (
                <IconButton
                    aria-label="Open in new tab"
                    onClick={() => {
                        window.open(text, "_blank");
                    }}
                    size="2xsmall"
                >
                    <ArrowTopRightOnSquare color="inherit" />
                </IconButton>
            )}
        </Stack>
    );
};

const TextHoverTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip leaveDelay={500} {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.arrow}`]: {
        color: getBorderToken("border300", theme),
    },
    [`& .${tooltipClasses.tooltip}`]: {
        borderRadius: theme.shape.borderRadius,
        borderColor: getBorderToken("border300", theme),
        borderWidth: "1px",
        borderStyle: "solid",
    },
}));

type Props = {
    text: string | undefined;
};

export const TextCustomField: React.FC<Props> = ({ text }) => {
    const isLink = useMemo(() => {
        try {
            if (!text) {
                return false;
            }
            // eslint-disable-next-line no-new
            new URL(text);
            return true;
        } catch {
            return false;
        }
    }, [text]);

    if (text == null) {
        return "";
    }

    return (
        <TextHoverTooltip size="small" title={<TooltipContent isLink={isLink} text={text} />}>
            <Typography noWrap variant="textSm">
                {isLink ? (
                    <Link href={text} target="_blank" rel="noreferrer">
                        {text}
                    </Link>
                ) : (
                    text
                )}
            </Typography>
        </TextHoverTooltip>
    );
};

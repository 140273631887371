import { Divider, Grid2 as Grid, Stack, TextField, Typography } from "@mui/material";
import { DesktopDatePicker } from "@mui/x-date-pickers";
import { FormattedMessage, useIntl } from "react-intl";
import { useState } from "react";
import dayjs from "dayjs";
import { Contract, ContractFields } from "@/types";
import { usePermissionCheck } from "@/hooks/usePermissionCheck";
import { useContractsApiFetch } from "@/hooks/useContractsApiFetch";
import { useCompanyCurrency } from "@/contexts/CompanyCurrencyContext";
import { normalizeDateString } from "@/helpers/dates";
import { contractDetailRoute } from "@/Router";
import { SupplierDropDownV2 } from "@/components/SupplierDropdownV2";
import { ContractField } from "../field";
import { SupplierChip } from "@/components/SuppliersChip";

interface ContractDetailsBasicDetailsProps {
    editMode: boolean;
    contract: Contract;
    setContract: (contract: Contract) => void;
}

export const ContractBasicDetails: React.FC<ContractDetailsBasicDetailsProps> = ({
    contract,
    editMode,
    setContract,
}) => {
    const { supplierName: initialSupplierName } = contractDetailRoute.useLoaderData();
    const { formatMessage } = useIntl();
    const canOpenSupplierCard = usePermissionCheck("suppliers", "general", "read").data ?? false;
    const [supplierName, setSupplierName] = useState<string | null>(initialSupplierName);
    const currency = useCompanyCurrency();

    const { data: columns } = useContractsApiFetch<ContractFields>("/contracts/metadata");

    const hasRenewalDate = columns === undefined || columns.specificFields.renewalDate !== undefined;

    return (
        <Stack gap={3}>
            <Typography variant="textLg" fontWeight={500}>
                <FormattedMessage defaultMessage="Basic details" />
            </Typography>
            <Divider />
            <Grid container rowGap={3} columnSpacing={3}>
                <ContractField edit={editMode} size={12} label={formatMessage({ defaultMessage: "Contract title" })}>
                    {contract.title}
                    <TextField
                        fullWidth
                        sx={{ minWidth: "260px" }}
                        value={contract.title}
                        onChange={(e) => setContract({ ...contract, title: e.target.value })}
                        placeholder={formatMessage({ defaultMessage: "Add title" })}
                    />
                </ContractField>
                <ContractField
                    blank={contract.description == null}
                    edit={editMode}
                    size={12}
                    label={formatMessage({ defaultMessage: "Description" })}
                >
                    <Typography variant="textMd">{contract.description}</Typography>
                    <TextField
                        minRows={3}
                        value={contract.description}
                        slotProps={{
                            htmlInput: {
                                autosize: true,
                                style: {
                                    resize: "vertical",
                                },
                            },
                        }}
                        onChange={(e) => setContract({ ...contract, description: e.target.value })}
                        multiline
                        fullWidth
                        placeholder={formatMessage({ defaultMessage: "Add description" })}
                    />
                </ContractField>
                <ContractField
                    size={8}
                    edit={editMode}
                    label={formatMessage({ defaultMessage: "Supplier" })}
                    blank={contract.supplierId == null}
                >
                    <Stack direction="row">
                        <SupplierChip
                            disabled={!canOpenSupplierCard}
                            name={supplierName ?? ""}
                            id={contract.supplierId ?? ""}
                        />
                    </Stack>
                    <SupplierDropDownV2
                        fullWidth
                        value={contract.supplierId ? { id: contract.supplierId, name: supplierName ?? "" } : null}
                        onChange={(row) => {
                            if (row && contract) {
                                setContract({ ...contract, supplierId: row.id });
                                setSupplierName(row.name);
                            }
                            if (!row && contract) {
                                setContract({ ...contract, supplierId: null });
                                setSupplierName("");
                            }
                        }}
                    />
                </ContractField>
                <ContractField
                    size={4}
                    label={formatMessage({ defaultMessage: "Total spend" })}
                    blank={contract.totalSpend === 0 || contract.totalSpend == null}
                    edit={editMode}
                >
                    {currency.formatInteger(contract.totalSpend)}
                    <TextField fullWidth disabled value={currency.formatInteger(contract.totalSpend)} />
                </ContractField>
                <ContractField
                    edit={editMode}
                    size={4}
                    label={formatMessage({ defaultMessage: "Start date" })}
                    blank={contract.startDate == null}
                >
                    {normalizeDateString(contract.startDate)?.format("DD MMM YYYY")}
                    <DesktopDatePicker
                        slotProps={{ textField: { fullWidth: true }, field: { clearable: true } }}
                        maxDate={contract.endDate ? dayjs(contract.endDate) : undefined}
                        value={normalizeDateString(contract.startDate)}
                        timezone="UTC"
                        onChange={(value) =>
                            setContract({
                                ...contract,
                                startDate: value == null ? null : value.format(),
                            })
                        }
                    />
                </ContractField>
                <ContractField
                    edit={editMode}
                    size={4}
                    label={formatMessage({ defaultMessage: "End date" })}
                    blank={contract.endDate == null}
                >
                    {normalizeDateString(contract.endDate)?.format("DD MMM YYYY")}
                    <DesktopDatePicker
                        slotProps={{ textField: { fullWidth: true }, field: { clearable: true } }}
                        value={normalizeDateString(contract.endDate)}
                        timezone="UTC"
                        minDate={contract.startDate ? dayjs(contract.startDate) : undefined}
                        onChange={(value) =>
                            setContract({
                                ...contract,
                                endDate: value == null ? null : value.format(),
                            })
                        }
                    />
                </ContractField>
                {hasRenewalDate && (
                    <ContractField
                        edit={editMode}
                        size={4}
                        label={formatMessage({ defaultMessage: "Renewal date" })}
                        blank={contract.renewalDate == null}
                    >
                        {normalizeDateString(contract.renewalDate)?.format("DD MMM YYYY")}
                        <DesktopDatePicker
                            slotProps={{
                                textField: { fullWidth: true },
                                field: { clearable: true },
                            }}
                            minDate={contract.startDate ? dayjs(contract.startDate) : undefined}
                            value={normalizeDateString(contract.renewalDate)}
                            timezone="UTC"
                            onChange={(value) =>
                                setContract({
                                    ...contract,
                                    renewalDate: value == null ? null : value.format(),
                                })
                            }
                        />
                    </ContractField>
                )}
            </Grid>
        </Stack>
    );
};
